.customer-account-logoutsuccess {
    .page-main {
        max-width: $layout__max-width;
        margin: 0 auto;
        text-align: center;

        .page-title-wrapper {
            margin: 117px 0 33px 0;

            .page-title {
                margin: 0;
                padding: 0;
            }
        }

        p {
            font-size: 16px;
            line-height: 1.5;
            text-align: center;
            color: $c_grey02;
        }
    }
}

.customer-address-form {

    .form-address-edit {
        max-width: 540px;

        .fieldset {
            .column-3 {
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            > .field {
                margin-bottom: 20px;
            }

            .field {
                &.country,
                &.region,
                &.zip {
                    width: 31%;
                }
            }
        }

        .message.info {
            background: #FDF0D5;
            color: #C07600;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 15px;
            border-radius: 0;
            padding: 10px 12px;
        }
    }
}
