
// Alternative colors
$c-white: #fff;
$c-dark: #1c2028;
$c-dark-green: #1E4D25;
$c-black: #000000;
$c-slate: #434c5e;
$c-slate2: #545e6f;
$c-battleship-grey: #646f82;
$c-pale-lilac: #e9e9eb;
$c-pale-grey: #f9f9fb;
$c-cloudy-blue: #bfc9cd;
$c-bluey-grey: #858c8f;
$c-clear-blue: #23abff;
$c-strawberry: #f42837;

$c_bg-grey: #f9f9fb;
$c_border-grey: #e2e5ec;

$c_text-dark: #1c2028;
$c_text-medium: #434c5e;
$c_text-light: #8c96a9;
$c_text-lighter: #e9e9eb;
$c_Text-grey: #545e6f;

$c-darkblue-bg : $c_text-dark;

$c_primary: #1c2028;
$c_primary-darker: #1c2028;
$c_secondary: #23abff;
$c_secondary-dark: $c_text-medium;

$c_grey: #8c96a9;
$c_grey--60: mix($c_grey, $c_bg-grey, 60%);
$c_grey--40: mix($c_grey, $c_bg-grey, 40%);

$c_grey02: #858c8f;
$c_grey03: #f2f2f2;
$c_grey04: #5e5e5e;
$c_grey05: #EAEAEA;
$c_red01: #81252A;
$c_main_blue: #0B154B;
