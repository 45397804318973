// Product Quote WebForm
.cms-quote {
    .page-title-wrapper {
        .page-title {
            text-align: center;
            margin: 68px 0 62px 0;
        }
    }
}

.webforms-product_quote {
    max-width: 540px;
    margin: auto auto 40px auto;

    @media screen and (max-width: $screen__m) {
        margin-bottom: 0;
    }

    .fieldset {
        margin-bottom: 0;

        .field {
            @include lib-vendor-prefix-display(flex);
            flex-direction: column;

            &.no-label {
                text-align: left;
                margin-bottom: 15px;
            }

            .label {
                width: auto;
                text-align: left;
                margin-bottom: 6px;
            }

            .control {
                width: 100%;
            }
        }
    }
    
    > .actions-toolbar {
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        margin-top: 25px;

        .primary {
            @media screen and (max-width: $screen__m) {
                width: 100%;
            }

            .action {
                @extend .primary--full;
            }
        }
    }
}
