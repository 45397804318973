//
//  Shopping cart
//  ---------------------------------------------

.checkout-cart-index {
    .page-main {
        max-width: $layout__max-width;
    }
}

.cart-empty {
    text-align: center;
    min-height: 300px;
    padding: 0 30px;

    &__title {
        font-size: 2.6rem;
        margin-top: 75px;
    }

    &__message {
        font-size: 3.2rem;
        margin-top: 68px;
        margin-bottom: 0;
    }

    &__actions {
        display: flex;
        justify-content: center;
        margin: 68px auto 0;
        max-width: 580px;

        > a {
            flex-basis: 50%;
            margin: 0 10px;
            max-width: 303px;
        }

        .primary--border {
            display: none;
        }
    }

    @include max-screen($screen__m) {

        &__title {
            margin-top: 30px;
            font-size: 2rem;
        }

        &__message {
            margin-top: 60px;
            font-size: 2.4rem;
        }

        &__actions {
            flex-direction: column;

            > a {
                flex-basis: 100%;
                margin: 0 0 20px 0;
            }
        }
    }
}

.cart-container {

    .form-cart {
        @extend .abs-shopping-cart-items;
        width: 100%;
        float: none;
        margin-top: 35px;
    }

    &__title {
        text-align: center;
        margin-top: 75px;
        margin-bottom: 30px;

        h1 {
            font-size: 2.6rem;
            margin-bottom: 15px;
            margin-top: 0;
        }

        p {
            color: $c-bluey-grey;
            font-size: 1.6rem;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        margin-top: 35px;
    }
}

//  Products table
.cart.table-wrapper {
    margin-bottom: 0;

    thead {
        @extend .abs-visually-hidden;
    }

    .items {

        > .item {
            border-bottom: $border-width__base solid $c-pale-lilac;
            position: relative;
        }
    }

    .col {
        padding-top: 38px;

        &.qty {
            text-align: center;

            .input-text {
                @extend .abs-input-qty;
                margin-top: -5px;
                padding-top: 7px;
                padding-bottom: 5px;
                box-shadow: 0 0 0 1px $c_grey02 inset;
            }

            .label {
                @extend .abs-visually-hidden;
            }

            .qty-action {
                margin-top: 10px;
                opacity: 0;

                button {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: center;
                    color: $c_grey02;
                    text-transform: none;

                }
            }
        }

        &.remove {
            text-align: right;

            a.action-delete {
                position: relative;
                font-size: 0;

                @include icomoon("cross", "before", true, true) {
                    font-size: 10px;
                    color: $c_grey02;
                }

                &:before {
                    margin-top: 6px;
                    display: block;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .item {

        .col {

            &.item {
                display: block;
                min-height: 75px;
                padding: 35px 0 35px 75px;
                position: relative;
            }
        }
    }

    .action {

        &.help.map {
            @extend .abs-action-button-as-link;
            font-weight: $font-weight__regular;
        }
    }

    .product {

        &-item-photo {
            display: block;
            left: 0;
            max-width: 60px;
            padding: 0;
            position: absolute;
            top: 15px;
            width: 100%;
        }

        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;
            padding: 0;
            font-size: 14px;
            text-transform: none;
            width: 100%;

            a {
                color: $c-dark;
            }
        }

        &-item-brand {
            display: inline-block;
            padding: 0 0 8px 0;
            font-size: 14px;
            font-weight: normal;
            width: 100%;
            text-transform: capitalize;
        }
    }

    .gift-registry-name-label {

        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-add-clearfix;
        @extend .abs-reset-list;
        margin-top: 0;

        li {
            color: $c-dark;
            font-size: 1.4rem;
        }

        .label {
            font-weight: normal;
            font-size: 14px;

            &:after {
                content: ":";
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }

    .price-including-tax .price,
    .price-excluding-tax .price {
        font-weight: normal;
        font-size: 1.4rem;
    }

    .col.subtotal {

        .price-including-tax .price,
        .price-excluding-tax .price {
            font-size: 1.4rem;
        }
    }

    .action.configure {
        display: inline-block;
        margin: 0 0 $indent__base;
    }
}

// Discount code
.column.main .block.discount {
    width: 100%;
    max-width: 465px;
    padding-right: 20px;

    .title {
    margin-bottom: 7px;

        strong {
            font-size: 1.3rem;
        }
    }

    .fieldset > .field:not(.choice) > .label {
        @extend .abs-visually-hidden;
        padding: 0;
    }

    .fieldset.coupon {
        display: flex;

        .field {
            width: 100%;
            margin-bottom: 0;
            margin-right: 10px;
        }

        .control {
            width: 100%;
            height: 100%;
        }

        .input-text {
            height: 48px;
        }

        .actions-toolbar {
            white-space: nowrap;
            margin: 0;

            .primary .action {
                margin-right: 0;

                @media screen and (max-width: $screen__m) {
                    padding: 16px 24px;
                }
            }
        }
    }
}

//  Summary block
.column.main .cart-summary {
    @extend .abs-add-box-sizing;
    @extend .abs-adjustment-incl-excl-tax;
    max-width: 335px;
    width: 100%;
    text-align: center;

    .block.shipping {
        margin-bottom: 30px;
        text-align: left;

        .title {
            strong {
                width: 100%;
                font-weight: 600;
                font-size: 1.4rem;
                position: relative;
                display: block;

                &:after {
                    content: "+";
                    position: absolute;
                    right: 0;
                    top: 2px;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }

        &.active {

            .title strong {

                &:after {
                    content: "-";
                }
            }
        }

        .fieldset > .legend {
            font-size: 1.4rem;
            margin-top: 24px;
        }

        .field[name="shippingAddress.postcode"] {
            width: 40%;
            padding-left: 20px;
            float: right;
        }

        .field[name="shippingAddress.region_id"] {
            width: 60%;
            float: left;
        }

        #co-shipping-method-form {
            @extend .abs-add-clearfix;
            margin-top: 20px;

            .label {
                font-size: 1.4rem;

                > .price {
                    font-weight: 600;
                    margin-left: 15px;
                }
            }

            .field.choice {
                margin-bottom: 5px;
                @include lib-vendor-prefix-display(flex);
                align-items: center;
                
                
                .label {
                    margin-left: 15px;
                    margin-bottom: 0;
                }
                
                .price {
                    margin-left: 0;
                }
            }
        }

        .select-wrapper:after, .checkout-payment-method .field-select-billing > .control:after {
            visibility: hidden;
        }
    }

    .continue {
        @extend .button-as-link;
        @extend .secondary--underline;
        margin-top: 15px;
    }
}

//  Totals block
.cart-totals {
    border-top: 1px solid $c_border-grey;
    padding-top: 30px;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;

        th,
        td {
            padding-top: 5px;
        }

        .grand.totals {
            font-size: 1.6rem;

            th,
            td {
                padding-top: 15px;
                width: 50%;
            }
        }

        .totals.shipping {

            .value {
                display: block;
                color: $c-bluey-grey;
            }
        }
    }

    table > thead > tr > td,
    table > tbody > tr > td,
    table > tfoot > tr > td {
        text-align: right;
    }

    table > thead > tr > th,
    table > thead > tr > td,
    table > tbody > tr > th,
    table > tbody > tr > td,
    table > tfoot > tr > th,
    table > tfoot > tr > td {
        padding: 2px 0;
        font-weight: normal;
    }
}

ul.checkout-methods-items {
    margin-top: 40px;
    padding-left: 0;

    .item:last-child {
        margin-top: 15px;
    }

    .action.multicheckout {
        @extend .button-as-link;
        @extend .secondary--underline;
    }

    .primary--full {
        width: 100%;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .cart-container {

        .form-cart {
            margin-top: 35px;
        }

        &__title {
            margin-top: 50px;

            h1 {
                font-size: 2rem;
                margin-bottom: 30px;
            }

            p {
                font-size: 14px;
            }
        }

        &__bottom {
            flex-direction: column;
            margin-top: 3rem;
        }
    }

    .cart {

        &.table-wrapper {
            overflow: inherit;
            position: relative;

            thead {

                .col {

                    &:not(.item) {
                        display: none;
                    }
                }
            }

            .col {

                &.qty,
                &.price,
                &.subtotal,
                &.msrp {
                    box-sizing: border-box;
                    display: block;
                    float: left;
                    text-align: center;
                    white-space: nowrap;
                    width: 33%;

                    &:before {
                        content: attr(data-th) ':';
                        display: block;
                        font-weight: $font-weight__bold;
                        padding-bottom: $indent__s;
                    }
                }

                &.msrp {
                    white-space: normal;
                }

                &.remove {
                    position: absolute;
                    top: 0;
                    right: 0;
                    padding-top: 0;

                    a.action-delete:after {
                        margin-top: -3px;
                    }
                }
            }

            .cart.item {
                width: 100%;

                &:not(:first-of-type) {
                    padding-top: 25px;
                }
            }

            .item {
                .col.item {
                    padding-bottom: 0;
                    padding-top: 0;
                    padding-left: 125px;
                    min-height: 120px;
                }

                .col.remove {
                    padding: 0;
                    top: 0;
                }

                .col.price,
                .col.qty,
                .col.subtotal {
                    padding-top: 25px;
                }
            }

            .product {

                &-item-photo {
                    top: 0;
                    max-width: 110px;
                }

                &-item-name {
                    font-size: 1.4rem;
                    padding-bottom: 7px;
                }



                &-item-details {
                    vertical-align: top;
                    white-space: normal;
                    width: 85%;

                    .item-options li {
                        padding-bottom: 7px;
                        margin: 0;
                    }
                }
            }
        }
    }

    .cart-container {

        .form-cart {
            @extend .abs-shopping-cart-items-mobile;
        }
    }

    .column.main .block.discount {
        padding-right: 0;
        margin-bottom: 40px;
        max-width: none;
    }

    .column.main .cart-summary {
        max-width: none;

        .block.shipping {

            .field[name="shippingAddress.postcode"] {
                width: 37%;
            }

            .field[name="shippingAddress.region_id"] {
                width: 57%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {

        @extend .abs-add-clearfix-desktop;

        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
        }
    }

    .cart-summary {
        position: relative;

        .block {

            .fieldset {

                .field {
                    @include lib-form-field-type-revert($_type: block);
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items { // Google Chrome version 44.0.2403.107 m fix
                min-width: 100%;
                width: auto;
            }

            .item {

                .col {

                    &.item {
                        padding: 38px 8px;
                    }
                }
            }

            .product {
                &-item-photo {
                    display: table-cell;
                    max-width: 100%;
                    padding-right: $indent__base;
                    position: static;
                    vertical-align: top;
                    width: 1%;
                }

                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {

        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart.table-wrapper {

        .cart-products-toolbar {

            + .cart {

                thead {

                    tr {

                        th.col {
                            padding-bottom: 7px;
                            padding-top: 8px;
                        }
                    }
                }
            }
        }

        .cart {

            + .cart-products-toolbar {
                margin-top: $indent__m;
            }
        }
    }
}
// Cart advantags section
.checkout-cart-index {
    .advantages {
        @include lib-vendor-prefix-display(flex);
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin: 135px 0;
        padding: 100px 0;
        border-top: solid 1px $c-pale-lilac;

        @media screen and (max-width: $screen__m) {
            margin: 100px 0;
            padding: 0;
            align-items: flex-start;
        }

        .advantage {
            text-align: center;
            margin: 0 49px;
            cursor: pointer;

            @media screen and (max-width: $screen__m) {
                margin: 35px 20px;
                width: 35%;
                box-sizing: border-box;
            }

            .icon {
                &:before {
                    font-size: 66px;
                }
            }

            .text {
                margin-top: 22px;
                font-size: 14px;
                line-height: 1.71;
                text-align: center;
                color: $c-dark;

                span {
                    display: block;
                    text-align: center;

                    @media screen and (max-width: $screen__m) {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

