body {
    &.navigation-mobile-open {
        overflow-y: hidden;
        height: 100vh;
        position: relative;

        .navigation-main-wrapper {
            .navigation {
                left: 0;
                z-index: 999999;
            }
        }

        .navigation-mobile-close {
            display: block;
        }

        .top-container {
            .block-search {
                z-index: -1;
            }

        }
    ;
    }

    &.navigation-mobile-loaded {

        .page-header {
            visibility: visible;
        }
    }

    .navigation-mobile-menu {

        @media screen and (max-width: $screen--l) {
            display: flex;
        }

        .navigation-mobile-open & {
            display: none;
        }
    }
}

.page-header {
    background-color: #fff;
    z-index: 99;
    transform: none !important;
    border-bottom: none;

    body.navigation-hover &,
    body.search-opened & {

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 200vh;
            background-color: rgba(28, 32, 40, 0.6);
            z-index: 5;
        }
    }
}

body.search-opened {
    overflow-x: hidden;
}

.top-header-message {
    background-color: $c-dark-green;
    display: none;

    &.opened {
        display: block;
    }

    .wrapper {
        max-width: 1440px;
        padding: 0 20px;
        margin: 0 auto;
        text-align: center;
        color: $c_white;
        font-size: 14px;
        line-height: 40px;

        @media screen and (max-width: $screen__l) {
            font-size: 12px;
            line-height: 30px;
        }

        a {
            color: $c-white;
            text-decoration: underline;
        }
    }

    &__close {
        float: right;
        cursor: pointer;
        line-height: 40px;
        //@include icomoon("close", "before", true, true) {
        //    font-size: 16px;
        //    color: $c-white;
        //}

        svg {
            vertical-align: middle;
        }

        @media screen and (max-width: $screen__l) {
            line-height: 30px;
        }
    }

    &-opened {
        margin-top: 160px;

        @media screen and (max-width: $screen__l) {
            margin-top: 90px;
        }
    }
}

.panel.wrapper {
    background-color: $c-darkblue-bg;
}

.panel.header {
    display: flex;
    align-items: center;
    padding-top: 8px;

    .action {
        display: none;
    }

    .top-nav {
        list-style-type: none;
        display: flex;
        padding: 0;
        margin-bottom: 0;

        li {
            margin-bottom: 0;
        }

        li + li {
            margin-left: 40px;
        }

        a {
            color: $c-white;
            font-size: 12px;

            &:hover {
                text-decoration: underline;
            }
        }

        &--left {
            flex-grow: 1;
        }

        &__link {
            display: flex;
            align-items: center;

            &--contact,
            &--our-stores {

                &:before {
                    content: '';
                    width: 12px;
                    height: 12px;
                    display: block;
                    background-size: 12px;
                    background-repeat: no-repeat;
                    margin-right: 6px;
                }
            }

            &--contact {

                &:before {
                    background-image: url('../images/icons/contact.svg');
                }
            }

            &--our-stores {

                &:before {
                    background-image: url('../images/icons/locate.svg');
                }
            }

            @media screen and (max-width: $screen--l) {
                padding: 0;
            }
        }
    }

    @media screen and (max-width: $screen--l) {
        display: none;
    }
}

.switcher.switcher-language {
    order: 7;
    margin: 0;
    position: relative;

    @media screen and (max-width: $screen__l) {
        display: none;
    }

    .switcher-label {
        display: none;
    }

    .switcher-options {

        @include icomoon("simple-arrow-down", "before", true, true) {
            font-size: 5px;
            color: $c-black;
        }

        &:before {
            position: absolute;
            top: 9px;
            right: 11px;
            display: none;
        }

        .switcher-trigger {
            display: none;
        }

        .switcher-dropdown {
            display: block;
            position: static;
            margin-top: 0;
            padding: 0;
            min-width: 0;
            background-color: transparent;
            border: none;
            box-shadow: none;
            list-style-type: none;
            margin-bottom: 0;

            &:after,
            &:before {
                content: initial;
            }

            .switcher-option {
                margin-bottom: 0;

                a {
                    color: $c-dark;
                    font-size: 20px;
                    font-weight: 500;
                    text-transform: uppercase;

                    .desktop {
                        display: block;

                        @media screen and (max-width: $screen__l) {
                            display: none;
                        }
                    }

                    .mobile {
                        display: none;

                        @media screen and (max-width: $screen__l) {
                            display: block;
                        }
                    }
                }
            }

            li {

                &:hover {
                    background-color: transparent;
                }
            }

            a {
                padding: 0;
                color: $c-dark;
                font-size: 16px;
                font-weight: 500;

                &:hover {
                    background-color: transparent;
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (max-width: $screen__l) {
    .switcher {
        border: 1px solid $c-pale-lilac;
        border-radius: 3px;
        font-size: 1.6rem;
        font-weight: normal;
        padding: 16px 12px;
        margin-top: 40px;
        width: 100%;
        box-sizing: border-box;

        &:before {
            display: block;
        }

        .label {
            display: block;
            margin-bottom: $indent__xs;
        }
    }

    .switcher-trigger {
        display: block !important;

        strong {
            position: relative;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1c2028;

            &:after {
                position: absolute;
                right: -40px;
                top: -16px;
            }
        }
    }
    .switcher-dropdown {
        display: none !important;
        padding: $indent__s 0;
    }
    .switcher-options {
        position: relative;

        &:before {
            display: block !important;
        }

        &.active {
            &:before {
                transform: rotate(180deg);
            }

            .switcher-dropdown {
                display: block !important;
                margin: 10px 0;

                .switcher-option a {
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-transform: none;
                }
            }
        }
    }
}

.header.content {
    position: initial;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 0;
    height: 105px;
    max-width: 1440px;

    &::before,
    &::after {
        content: unset;
    }

    @media screen and (min-width: 1221px) {
        padding: 0 102px !important;
    }

    @media screen and (max-width: 1220px) {
        padding: 0 20px !important;
    }

    @media screen and (max-width: 1024px) {
        height: 60px;
    }

    .nav-toggle {
        order: -1;
        position: static;
    }

    .find-us-wrapper,
    .header.misc,
    .switcher-language {
        margin: 0 10px;
    }


    .logo {
        margin: 0;
        order: 1;

        @media screen and (max-width: $screen__l) {
            order: unset;
        }

        a {
            display: block;
            width: 200px;

            @media screen and (max-width: $screen__l) {
                width: 140px;

                svg {
                    width: 100%;
                }
            }
        }
    }

    .block-search {
        @media screen and (max-width: $screen__l) {
            display: none;
        }
    }

    .find-us-wrapper {
        order: 6;
        list-style: none;
        margin: -5px 0 0 0;
        padding: 0;

        @media screen and (max-width: $screen__l) {
            margin: 0;
            display: none;
        }

        li {
            margin: 0;
        }

        .find-us {
            font-size: 0;

            @include icomoon("location", "before", true, true) {
                font-size: 20px;
                color: $c-black;
            }

            @media screen and (max-width: $screen__l) {
                font-size: 14px;
                color: $c-dark;
                margin: 23px 0 38px 0;
                padding: 0;

                &:before {
                    display: none;
                }
            }

            &:hover {
                text-decoration: none;
            }
        }
    }

    .header.misc {
        order: 10;

        @media screen and (max-width: $screen--l) {
            display: none;
        }
    }

    .link-cart {
        @media screen and (min-width: $screen--l) {
            display: none;
        }
    }

    .minicart-wrapper {
        order: 11;
        margin-left: 10px;

        .action.showcart {
            vertical-align: middle;

            @include icomoon("basket", "before", true, true) {
                color: $c-black;
                font-size: 20px;
            }

            .qty {
                background-color: transparent;
                vertical-align: middle;
                font-size: 14px;
                text-align: center;
                margin: 0;
                padding: 0 0 0 3px;
                color: $c-primary;

                &.empty {

                    &:before,
                    &:after {
                        content: none;
                    }
                }

                .loading-mask {
                    display: none;
                }
            }

        }

        .minicart-title {
            display: none;
        }

        .block-minicart {
            border: none;
            top: 52px;
            width: 320px;
            padding: 0;

            &:before,
            &:after {
                content: none;
            }
        }

        .block-content {
            padding-top: 27px;

            .action.close {
                display: none;
            }

            .items-total {
                margin-left: 0;
                margin-bottom: 18px;
                padding-left: 30px;
                font-size: 16px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 19px;
                float: none;

                .count {
                    font-weight: bold;

                    &:before {
                        content: '(';
                    }

                    &:after {
                        content: ')';
                    }
                }
            }

            .minicart-items {
                .product-item {
                    padding: 15px 0 0 0;
                    max-width: 100%;

                    > .product {
                        .product-item-details {
                            @include lib-vendor-prefix-display(flex);
                            justify-content: space-between;
                            padding-left: 12px;
                            align-items: stretch;
                            margin-bottom: 15px;

                            .left-block {
                                padding-right: 15px;
                                width: 80%;

                                .product-item-name {
                                    padding: 0;
                                    font-size: 12px;
                                    letter-spacing: 0;
                                    line-height: 15px;
                                    margin-bottom: 6px;

                                    a {
                                        text-transform: none;
                                        text-decoration: none;
                                    }
                                }

                                .details-qty {
                                    margin-top: 6px;

                                    > .label,
                                    > .control {
                                        display: inline-block;
                                        color: $c-black;
                                        font-size: 12px;
                                        letter-spacing: 0;
                                        line-height: 15px;
                                        font-weight: normal;
                                    }
                                }
                            }

                            .right-block {
                                display: flex;
                                flex-direction: column;
                                align-items: stretch;
                                justify-content: space-between;

                                .product-item-pricing {
                                    .price {
                                        font-size: 12px;
                                        letter-spacing: 0;
                                        line-height: 15px;
                                        text-align: right;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .subtotal {
                @include lib-vendor-prefix-display(flex);
                justify-content: space-between;
                align-items: center;
                padding: 0 30px;
                margin: 0;

                > .label,
                > .amount.price-container {
                    display: inline-block;
                    text-align: left;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 19px;
                    font-weight: bold;
                }

                > .amount.price-container .price {
                    font-size: 14px;
                    font-weight: bold;
                }
            }

            .actions {
                padding-left: 30px;
                padding-right: 30px;
                margin-top: 25px;

                > .primary {
                    box-sizing: border-box;
                    margin: 0;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 1.5px;
                    line-height: 14px;
                    text-align: center;
                    height: 50px;

                    @media screen and (max-width: $screen__l) {

                        .checkout {
                            padding: 16px 10px 15px 10px;
                        }

                    }
                }

                > .secondary {
                    margin: 30px 0;

                    .viewcart {
                        display: block;
                        text-align: center;
                        color: $c-dark;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 16px;

                        &:hover {
                            color: $c-text-dark
                        }
                    }
                }

                .action.primary {
                    @extend .primary--full;
                }
            }
        }

        @media screen and (max-width: $screen--l) {
            margin-top: 0;
            margin-left: 0;
        }
    }

    .header.misc {

        .header.links {
            position: relative;
            list-style-type: none;
            padding: 0;
            margin: 0;

            > .authorization-link {
                margin-bottom: 0;

                > a {
                    text-decoration: none;
                    font-size: 0;

                    @include icomoon("account", "before", true, true) {
                        color: $c-black;
                        font-size: 20px;
                    }

                    span {
                        display: none;
                    }

                    &[href*="logout"] {
                        pointer-events: none;
                    }
                }
            }

            .customer-welcome {
                width: 20px;
                height: 35px;
                position: absolute;
                top: 4px;
                left: 50%;
                z-index: 10;
                transform: translateX(-50%);
                background-color: $c-white;
                border: none;

                @include icomoon("account", "before", true, true) {
                    color: $c-black;
                    font-size: 20px;
                }

                @media screen and (min-width: $screen__l) {
                    + .authorization-link {
                        display: none;
                    }

                    .find-us-wrappercustomer-name {
                        text-transform: uppercase;
                    }

                    .customer-menu {
                        display: none;
                        position: absolute;
                        top: 35px;
                        right: -12px;
                        background-color: $c-white;
                        border: 1px solid $c-dark;
                        box-shadow: 0 5px 5px -1px rgba($c-dark, 0.5);
                        white-space: nowrap;
                        padding: 15px;
                        width: 110px;

                        .header.links {
                            margin-left: 0;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            max-width: 140px;
                            width: 100%;
                            position: relative;

                            li {
                                margin-bottom: 19px;

                                &:nth-child(1) {
                                    order: 3;
                                }

                                &:nth-child(2) {
                                    order: 2;
                                    position: relative;
                                    padding-bottom: 15px;

                                    &:after {
                                        content: '';
                                        width: 20px;
                                        height: 1px;
                                        background: #e9e9eb;
                                        position: absolute;
                                        bottom: 0;
                                        left: 50%;
                                        transform: translateX(-50%);
                                    }
                                }

                                &:nth-child(3) {
                                    order: 5;
                                    margin-bottom: 0;
                                }

                                &:nth-child(6),
                                &:nth-child(9) {
                                    order: 4;
                                }

                                &:nth-child(8) {
                                    order: 1;
                                }
                            }

                            a {
                                color: $c-dark;
                                font-size: 14px;

                                &:hover {
                                    color: $c-text-light;
                                }
                            }

                            .authorization-link {
                                order: 10;

                                > a {

                                    span {
                                        display: flex;
                                    }

                                    svg {
                                        display: none;
                                    }

                                    &[href*="logout"] {
                                        pointer-events: all;
                                    }

                                    &:before {
                                        display: none;
                                    }
                                }
                            }

                            .greet.welcome,
                            .action.close {
                                display: none;
                            }

                        }
                    }

                    &:hover {

                        .customer-menu {
                            display: block;

                            &:after {
                                content: '';
                                border-left: 6px solid transparent;
                                border-right: 6px solid transparent;
                                border-bottom: 6px solid #1c2028;
                                right: 15px;
                                top: -7px;
                                position: absolute;
                            }
                        }
                    }
                }

            }

            body.customer-opened & {

                .customer-welcome {
                    display: flex;
                }
            }

        }
    }

    .minicart-wrapper {
        .block-minicart {
            border: solid 1px $c-dark;
            background: $c-white;
            width: 395px;
            top: 30px;
            right: -25px;

            @media screen and (max-width: 1200px) {
                right: 0;
            }

            @media screen and (max-width: $screen__m) {
                width: calc(100vw - 40px);
                right: 0;
            }

            #minicart-content-wrapper {
                position: relative;

                &:after {
                    content: '';
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid $c-dark;
                    left: 354px;
                    top: -7px;
                    position: absolute;

                    @media screen and (max-width: 1200px) {
                        right: 2px;
                        left: auto;
                    }

                    @media screen and (max-width: 768px) {
                        right: 2px;
                        left: auto;
                    }
                }
            }

            .subtitle.empty {
                padding-top: 0;
                padding-bottom: 27px;
            }
        }
    }

    .minicart-items-wrapper {
        background-color: $c-white;
        margin: 0;
        padding: 20px 30px;
        border: none;
        max-height: 300px;
        overflow-y: auto;

        .item.product {

            .product-item-details {

                .product-item-name {
                    margin-bottom: 6px;

                    a {
                        font-size: 14px;
                        text-decoration: underline;
                        text-transform: uppercase;
                        color: $c-black;

                        &:hover {
                            color: $c-black;
                        }
                    }
                }

                .product.options {

                    span.toggle {
                        display: none;
                    }

                    div.content {
                        display: block !important;
                    }
                }

                .product.options.list {
                    margin-bottom: 0;

                    .label,
                    .values {
                        color: $c-black;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 15px;
                        display: inline-block;
                        font-weight: normal;
                        padding: 0;
                        margin: 0;
                    }

                    .label {
                        &:after {
                            content: ': ';
                        }
                    }

                    .values {
                        padding-left: 5px;
                    }
                }

                .price-container {
                    text-align: right;
                }

                .product-item-pricing {

                    .price-excluding-tax {
                        font-size: 14px;
                        font-weight: 700;
                        margin-bottom: 12px;
                        margin-top: 0;
                    }

                    .details-qty {

                        .label {
                            display: none;
                        }

                        .update-cart-item {
                            padding: 0;
                            margin-top: 4px;
                            font-size: 12px;
                            text-decoration: underline;
                            color: $c-text-light;
                            border: none;
                            background-color: transparent;
                        }

                        input {
                            border-width: 1px;
                            border-radius: 2px;
                            font-size: 14px;
                        }
                    }
                }

                .product.actions {
                    margin: 0;
                    padding-right: 0;

                    .primary {
                        display: none;
                    }

                    .secondary {

                        a {

                            &:before {
                                content: '';
                                background-image: url('../images/icons/remove.svg');
                                width: 15px;
                                height: 15px;
                                background-size: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
.top-container {
    position: relative;
    padding: 0;
    z-index: 9;

    @media screen and (max-width: $screen__l) {
        .block-search {
            display: block;
            background: $c_main_blue;
            padding: 13px 20px;

            form {
                display: flex;
            }

            input {
                flex: 1;
                background: $c-white;
                line-height: 24px;
                border-radius: 24px;
                padding: 0 15px;
                font-size: 12px;

                &::-webkit-input-placeholder {
                    color: $c-grey04;
                }

                &.focus-visible {
                    box-shadow: 0 0 0 1px #1c2028 inset;
                }
            }
            .actions {
                padding-left: 20px;
            }

            .field.search {
                flex: 1;
            }

            .action.search {
                position: unset;
            }
        }
    }
}

.navigation {
    .navigation-projects {
        display: block;

        @media screen and (max-width: $screen__l) {
            order: unset;
            margin: 40px 0 50px 0;
            width: 100%;
            box-sizing: border-box;
            padding: 18px 29px;
        }
    }


    .find-us-wrapper {
        display: block;

        @media screen and (max-width: $screen__l) {
            font-weight: normal;
            order: 9;
            margin: 0;
            padding: 0;
            line-height: normal;

            li a {
                padding: 0;
                margin: 0;
                line-height: normal;
                display: inline-block;
                color: $c-dark;
            }
        }
    }
}

.navigation-mobile {

    &-menu,
    &-close {
        display: none;
    }

    @media screen and (max-width: $screen--l) {

        &-menu {
            display: block;
            order: -1;
        }
    }
}
body.menu-opened {
    .menu-overlay.show {
        visibility: visible;
    }
}

.menu-overlay {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    visibility: hidden;
    z-index: 1;
}

.navigation-main-wrapper {
    height: 100%;
    flex-grow: 1;
    background: $c_main_blue;

    @media screen and (max-width: $screen__l) {
        margin-left: 0;

        .top-header-message-opened & {
            @media screen and (max-width: $screen__l) {
                top: -30px;
            }
        }

        .switcher.switcher-language {
            display: block;
            margin: 39px 0;

            &:before {
                display: block;
            }

            .switcher-label {

            }

            .switcher-trigger {
                line-height: normal;
            }
        }

        .switcher.switcher-language .switcher-options .switcher-dropdown .switcher-option a {
            font-size: 14px;
            margin-top: 10px;
            text-transform: none;
        }
    }

    @media screen and (max-width: $screen__l) {
        position: absolute;
        top: -60px;
        left: 0;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex-grow: initial;
        overflow-y: scroll;
        overflow-x: hidden;
        z-index: 9999;
        display: block;
        background-color: transparent;
        transition: transform .24s ease-in;
        transition-delay: .24s;
        pointer-events: none;

        .navigation-mobile-open & {
            z-index: 9999;
            background-color: rgba(28, 32, 40, 0.8);
        }

        .navigation-mobile-close {
            background-color: transparent;
            position: absolute;
            top: 10px;
            left: calc(100vw - 42px);
            border-radius: 0;
            width: 42px;
            height: 42px;
            z-index: 99999999;
            cursor: pointer;
            pointer-events: all;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
            }
        }
    }
}

.navigation {
    position: static;
    flex-grow: 1;
    display: flex;
    background-color: transparent;
    transition: background-color .24s ease-in;
    transition-delay: .24s;
    max-width: $layout__max-width;
    width: 100%;
    margin: 0 auto;
    padding: 0 102px;
    line-height: 61px;

    > ul {
        position: static;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        width: 100%;
        padding: 0 !important;

        > li.level0,
        > li.navigation-static-link {
            position: static;
            height: 100%;
            display: flex;
            justify-content: space-between;
            margin: 0;
            border-top: 0;

            > a {
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                //letter-spacing: normal;
                text-align: center;
                color: $c-white;
                text-transform: uppercase;
                cursor: pointer;
                padding: 21px 0;
                letter-spacing: 0.36px;

                @media screen and (max-width: 1220px) {
                    margin: 0 15px;
                }

                > span {
                    display: block;
                    margin-left: 0 !important;
                }

                &.level-top,
                &.level-top:hover,
                &:hover{
                    border: none !important;
                    color: $c-white;
                    text-decoration: none;

                    @media screen and (max-width: $screen__l) {
                        color: $c-black;
                    }
                }

                &.level-top {
                    line-height: 61px;
                }
            }

            > ul {
                display: none;
            }

            ul {
                box-shadow: none;
                border: none;
            }

            &.has-active,
            &.active,
            &:hover {

                > a {
                    height: 100%;
                    position: relative;
                    font-weight: bold;
                    letter-spacing: 0;

                    border: none !important;

                    @media screen and (max-width: $screen__l) {
                        font-weight: normal;
                    }
                }
            }

            &.active,
            &.has-active,
            &.navigation-mobile-touch {

                > ul {
                    background-color: $c_white;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    width: 96%;
                    transform: translateX(-50%);
                    z-index: 10;
                    border: none;
                    display: block;

                    &.level0.submenu {
                        max-width: $layout__max-width;
                        width: 100%;
                        margin: 0 auto;
                        min-height: 500px;

                        @media screen and (max-width: $screen__l) {
                            min-height: auto;
                        }
                    }

                    > li {
                        margin-bottom: 0;
                    }

                    li {
                        a {
                            color: $c-black;
                            padding: 0;
                            font-weight: 400;

                            &:hover {
                                background-color: transparent;
                                color: $c-text-dark;
                            }
                        }
                    }
                }

                li.level1 {
                    min-width: 172px;

                    > a {
                        margin-bottom: 32px;
                        font-size: 14px;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: 1px;
                        color: $c-dark-green;
                        font-weight: 600;

                        @media screen and (max-width: $screen__l) {
                            font-size: 14px;
                            font-weight: 500;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.14;
                            letter-spacing: 1px;
                            color: $c-cloudy-blue;
                        }
                    }
                }

                ul.level1 {
                    display: none;
                }

                li.level2 {
                    display: none;
                    margin-bottom: 10px;

                    > a {
                        margin-bottom: 12px;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.14;
                        letter-spacing: normal;
                        color: #1c2028;
                    }
                }

                ul.level2 {
                    display: none;
                }

            }

            &.navigation-mobile-touch {

                > ul {
                    height: auto !important;
                    box-shadow: none;
                }
            }
        }
    }

    .level0 .submenu {
        background: $c_white;
        position: relative;

        > li.level1.category-item {
            display: flex;
            align-items: flex-start;
            padding: 0;
            margin: 0;
            position: unset;

            @media screen and (max-width: $screen__l) {
                &.parent {
                    position: relative;

                    &:after {
                        content: '+';
                        position: absolute;
                        right: 0;
                        top: -3px;
                    }

                    &.has-active, &.active {
                        &:after {
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            > a.level1.category-item {
                display: block;
                background-color: $c-grey03;
                color: $c-black;
                cursor: pointer;
                padding: 19px 20px 19px 70px;
                margin: 0;
                position: relative;
                width: 153px;
                z-index:100;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;

                @media screen and (max-width: $screen__l) {
                    background: transparent;
                    padding: 0 0 0 8px;
                    width: 100%;
                    text-transform: none;
                    font-weight: normal;
                    color: $c_grey04;
                    font-size: 14px;

                    &:not(:last-child) {
                        margin-bottom: 20px;
                        width: auto;
                    }
                }
            }

            > ul.level1.category-item {
                padding: 38px 60px 50px 60px;
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;

                > .category-item {
                    width: 33.3%;
                    box-sizing: border-box;
                    margin: 0;
                    padding: 12px 0;

                    > a {
                        color: $c-black;
                        font-size: 14px;
                        line-height: 19px;
                        font-weight: normal;
                        padding: 0;
                        margin: 0;
                        border: none;
                    }
                }

                > .level1 > a,
                > .level1 .level2 {
                    width: 100%;
                }
            }

            &.active,
            &.has-active {
                > a.level1 {
                    border-style: none;
                    border-width: 0;
                }
            }

            &:hover,
            &.active,
            &.has-active {
                > a.level1.category-item {
                    border-style: none;
                    border-width: 0;
                    background: $c-white;
                }

                > ul.level1.category-item {
                    display: flex;
                    flex-wrap: wrap;
                    align-content: flex-start;
                    background: $c-white;
                    position: absolute;
                    top: 0;
                    left: 243px;
                    width: calc(100% - 243px);
                    box-sizing: border-box;
                    z-index: 99;
                    min-height: 500px;

                    @media screen and (max-width: $screen__l) {
                        display: block;
                        margin-top: -10px;
                        position: initial;
                        padding: 0 20px 20px;
                        width: 100%;
                        min-height: auto;

                        > .category-item {
                            width: 100%;
                            padding: 8px 0;
                        }
                    }

                    &:after {
                        content: "";
                        background-color: $c-grey03;
                        top: 0;
                        bottom: 0;
                        display: block;
                        left: -243px;
                        position: absolute;
                        width: 243px;
                        z-index:1;
                    }
                }

                .static-include {
                    display: flex;
                    flex-direction: row-reverse;

                    @media screen and (max-width: $screen__l) {
                        display: none;
                    }

                    .nav-static-wrapper {
                        padding-left: 100px;
                        max-width: 800px;

                        .banner-wrapper {
                            display: flex;
                            @media screen and (max-width: $screen__l) {
                                display: none;
                            }

                            > .inner-block:first-child {
                                margin-right: 34px;
                            }
                        }
                    }

                    .level1-items {
                        flex: 1;

                        > ul {
                            display: flex;
                            flex-wrap: wrap;
                            align-content: flex-start;

                            > .category-item {
                                width: 33.3%;
                                box-sizing: border-box;
                                margin: 0;
                                padding: 12px 0;

                                > a {
                                    line-height: normal;
                                    border: none;
                                }
                            }
                        }
                    }

                    &.items-1column {
                        .level1-items {
                            > ul {
                                > .category-item {
                                    width: 100%;
                                }
                            }
                        }
                    }
                }
            }

            > ul.level1.category-item {
                display: none;
            }
        }

        > li.level1.category-item:not(:last-child) {
            > a.level1.category-item {
                @media screen and (max-width: $screen__l) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .navigation-static-link {
        .category-services {
            display: none;
            padding: 25px 15px;

            @media screen and (max-width: $screen__l) {
                padding-top: 0;
                padding-bottom: 0;
            }

            > li {
                flex: 1;
                max-width: 300px;
                margin: 0 20px;

                @media screen and (max-width: $screen__l) {
                    padding: 0;
                    margin: 0;

                    > a > img {
                        display: none;
                    }
                }

                > a:hover {
                    text-decoration: none;
                }

                > a > span {
                    display: block;
                    padding: 28px 0 41px 0;
                    border-bottom: solid 1px #979797;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    text-align: left;
                    color: $c-black;

                    @media screen and (max-width: $screen__l) {
                        color: $c_grey04;
                        background: $c-white;
                        text-align: left;
                        width: 100%;
                        box-sizing: border-box;
                        text-transform: none;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 16px;
                        letter-spacing: 1px;
                        padding: 10px 20px 10px 10px;
                        border-bottom: none;

                        &:first-of-type {
                            padding-top: 5px;
                        }
                    }
                }
            }
        }

        &.active {
            .category-services {
                display: flex;
                justify-content: space-between;
            }
        }
    }

    @media screen and (max-width: $screen--l) {
        position: relative;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background-color: #fff;
        width: calc(100vw - 42px);
        max-width: 320px;
        height: 100vh;
        padding: 0 20px;
        margin: 0;
        font-weight: normal;
        //overflow-y: scroll;
        padding-bottom: 80px;
        left: -320px;
        transition: left .24s ease-in;
        pointer-events: all;
        overflow: scroll;
        overflow-x: hidden;

        .navigation-main,
        .header.links,
        .panel.header {
            width: 100%;
        }

        > ul {
            display: inline-block;
            width: 100%;
            height: auto;
            background-color: #fff;
            justify-content: flex-start;
            align-items: flex-start;
            margin-top: 15px;


            li.level0,
            li.navigation-static-link {
                width: 100%;
                margin-right: 0;
                flex-direction: column;
                align-items: flex-start;
                height: auto;

                @media screen and (max-width: $screen__l) {
                    &:last-child {
                        border-bottom: none;
                    }
                }


                &.has-active,
                &:hover {

                    > .level-top {
                        border: none !important;
                        height: auto;
                    }

                    > ul {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

                > a {
                    padding: 17px 60px 17px 13px;
                    display: block;
                    width: 100%;
                    position: relative;

                    @media screen and (max-width: $screen__l) {
                        color: $c-black;
                        padding: 18px 0;
                        margin: 0;
                        box-sizing: border-box;
                        text-align: left;
                        text-transform: none;

                        span {
                            margin: 0 20px;
                            display: inline-block;
                        }
                    }
                }

                &.parent {

                    > a {
                        &:before {
                            position: absolute;
                            display: block;
                            content: '+';
                            font-size: 22px;
                            top: 50%;
                            transform: translateY(-50%);
                            right: 0;
                            width: 20px;
                            padding: 0 5px;
                            text-align: center;
                        }

                        &:after {
                            content: none !important;
                        }
                    }
                }

                ul.level0 {
                    height: 0;
                    position: static;
                    display: flex;
                    flex-direction: column;
                    transform: none;
                    overflow: hidden;
                    padding-left: 40px;

                    @media screen and (max-width: $screen__l) {
                        padding-left: 0;
                        display: block;
                    }

                    li.level1 {
                        width: 100%;
                        flex-direction: column;

                        a {
                            margin-bottom: 14px;
                        }
                    }

                    &:before,
                    &:after {
                        content: none;
                    }

                    ul.level1 {
                        padding-top: 6px;
                        padding-left: 20px;
                        padding-bottom: 10px;
                    }

                    &.submenu {
                        min-height: auto !important;
                        background: $c-white;
                        padding: 0;
                        margin: 0;

                        > .level0 {
                            background: $c-white;
                            margin: 0;
                            border-top: none;

                            > a.level1 {
                                color: $c_grey04;
                                background: $c-white;
                                text-align: left;
                                width: 100%;
                                box-sizing: border-box;
                                text-transform: none;
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 16px;
                                letter-spacing: 1px;
                                padding: 10px 20px 10px 10px;

                                &:first-of-type {
                                    padding-top: 5px;
                                }

                                &:before {
                                    display: none;
                                }

                                span {
                                    margin: 0;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }

                &.navigation-mobile-touch {

                    > a {
                        color: $c-black;

                        &:before {
                            content: '—';
                            font-size: 12px;
                            font-weight: bold;
                        }
                    }

                    > ul {
                        height: auto;
                        padding-top: 6px;
                    }
                }
            }
        }

        .header.misc {
            display: block;
        }

        .header.links {
            padding-left: 0;
            display: inline-block;

            .authorization-link {
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 1px;
                    width: 100px;
                }

                a {
                    text-transform: uppercase;
                    font-weight: 600;
                    padding: 25px 0;
                }

                svg {
                    display: none;
                }

                @media screen and (max-width: $screen__l) {

                    > a {
                        padding: 0;
                        text-transform: none;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: $c-dark;


                        > span {
                            display: inline-block;
                            padding: 38px 0;
                            border-top: solid 1px #e9e9eb;
                            border-bottom: solid 1px #e9e9eb;
                        }
                    }
                }
            }
        }

        .panel.header {
            position: relative;
            display: inline-block;
            background-color: #fff;
            align-items: flex-start;
            margin: 0;
            padding-top: 30px;
            padding-left: 20px;

            ul {
                display: flex;
                flex-direction: column;
                margin-left: 0;
                margin-bottom: 20px;

                li {

                    a {
                        padding: 0;
                        font-weight: 400;
                        color: #000;
                        font-size: 14px;

                        &:before {
                            display: none;
                        }
                    }
                }

                li + li {
                    margin-left: 0;
                    margin-top: 20px;
                }
            }

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 20px;
                height: 1px;
                width: 100px;
                background-color: $c_text-lighter;
            }
        }
    }

    @media screen and (max-width: $screen--l) {
        z-index: 10;
    }
}

.navigation .header.links {
    @media screen and (max-width: $screen__l) {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        position: relative;
        padding: 38px 0 10px 0;

        > .authorization-link {
            &:not(:last-of-type) {
                //display: none;
            }
        }

        &:not(:first-of-type) {
            &:before,
            &:after {
                content: '';
                background: $c-pale-lilac;
                height: 1px;
                width: 80px;
                position: absolute;
            }

            &:before {
                top: 0;
                left: 0;
            }

            &:after {
                bottom: 0;
                left: 0;
            }
        }

        li {
            padding: 0 ;
            margin-bottom: 36px;

            &.greet.welcome {
                margin-bottom: 28px;
                margin-top: 28px;
                line-height: normal;
            }

            a {
                padding: 0;
                color: $c-dark;
                line-height: normal;
            }

            &:nth-child(1) {
                order: 3;
            }

            &.link-cart {
                order: 4;
            }

            &:nth-child(6) {
                order: 1;
            }

            &.wishlist {
                order: 2;
            }

            &:nth-child(7) {
                order: 5;
            }
        }

        .customer-menu {
            > .header.links {
                padding: 0;
            }
        }

        .greet.welcome {
            span {
                font-size: 24px;
                font-weight: normal;
                line-height: 1.17;
                letter-spacing: 0.5px;
            }
        }

        .action.close {
            display: none;
        }

        .authorization-link {
            order: 10;
            margin-bottom: 28px;
            margin-top: 28px;

            &.logged-in {
                margin-top: 0;

                &:last-of-type {
                    display: none;
                }
            }

            > a > span {
                padding: 0;
                border: none;
            }
        }
    }
}
