
.swatch-attribute {
    margin-bottom: 30px;
}

.swatch-attribute.size {

    .size-guide-link {
        margin-top: 10px;
        display: block;
    }

    .size-guide-link--mobile {
        display: none;
    }

    @include max-screen($screen__m) {

        .size-guide-link--desktop {
            display: none;
        }

        .size-guide-link--mobile {
            display: inline-block;
        }
    }
}

.swatch-attribute-label {
    position: relative;
    font-weight: 600;

    &:after {
        content: ":";
    }
}

.swatch-attribute-selected-option {
    padding-left: 5px;
    font-weight: 600;
}

.swatch-attribute-options {
    margin-top: 10px;
    display: flex;
}

.swatch-option {
    text-align: center;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
}

.swatch-option.text {
    width: 20%;
    height: 42px;
    line-height: 45px;
    border: 1px solid $c-pale-lilac;
    border-radius: 4px;

    &:hover {
        border: 1px solid $c-black;
    }

    &.selected {
        color: $c-white;
        background-color: $c-black;
        border-color: $c-black;
    }
}

.swatch-option.color {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 24px;
        height: 24px;
        transform: translate(-50%, -50%);
        border: 2px solid $c-white;
        border-radius: 100%;
        opacity: 0;
    }

    &:hover,
    &.selected {

        &:after {
            opacity: 1;
        }
    }
}

.clearfix:after {
    content: '';
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
}

.swatch-option.disabled {
    cursor: default;
}

.swatch-option.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(
        to left top,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 42%,
        rgba(255, 255, 255, 1) 43%,
        rgba(255, 255, 255, 1) 46%,
        rgba(255, 82, 22, 1) 47%,
        rgba(255, 82, 22, 1) 53%,
        rgba(255, 255, 255, 1) 54%,
        rgba(255, 255, 255, 1) 57%,
        rgba(255, 255, 255, 0) 58%,
        rgba(255, 255, 255, 0) 100%
    );
}

.swatch-option-tooltip {
    max-width: 140px;
    max-height: 100%;
    min-height: 20px;
    min-width: 20px;
    position: absolute;
    padding: 5px;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    text-align: center;
}

.swatch-option-tooltip .corner,
.swatch-option-tooltip-layered .corner {
    left: 40%;
    position: absolute;
    bottom: 0;
    height: 8px;
}

.swatch-option-tooltip .corner:after,
.swatch-option-tooltip-layered .corner:after {
    content: '';
    position: relative;
    top: 1px;
    left: -15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 7px 7.5px 0 7.5px;
    border-color: #fff transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .corner:before,
.swatch-option-tooltip-layered .corner:before {
    content: '';
    position: relative;
    top: 2px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 8px 8.5px 0 8.5px;
    border-color: #adadad transparent transparent transparent;
    font-size: 1px;
}

.swatch-option-tooltip .image,
.swatch-option-tooltip-layered .image {
    display: block;
    height: 130px;
    width: 130px;
    margin: 0 auto;
}

.swatch-option-tooltip .title {
    max-width: 140px;
    min-height: 20px;
    max-height: 200px;
    color: rgb(40, 40, 40);
    text-align: center;
    display: block;
    overflow: hidden;
}

.swatch-opt {
    margin: 20px 0;
}

.swatch-more {
    display: inline-block;
    margin: 2px 0;
    text-decoration: none !important;
    position: static;
    z-index: 1;
}

// Layered Features
.swatch-option-link-layered {
    position: relative;
    margin: 0 !important;
    padding: 0 !important;
}

.swatch-option-link-layered:focus > div {
    box-shadow: 0 0 3px 1px #68a8e0;
}

.swatch-option-tooltip-layered {
    width: 140px;
    position: absolute;
    background: #fff;
    color: rgb(148, 148, 148);
    border: 1px solid #adadad;
    display: none;
    z-index: 999;
    left: -47px;
}

.swatch-visual-tooltip-layered {
    height: 160px;
    top: -170px;
}

.swatch-textual-tooltip-layered {
    height: 30px;
    top: -40px;
}

.swatch-option-link-layered:hover > .swatch-option-tooltip-layered {
    display: block;
}

.swatch-attribute-options .swatch-option-tooltip-layered .title {
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: -5px;
    left: 0;
    color: rgb(40, 40, 40);
    text-align: center;
    margin-bottom: 10px;
}

.swatch-option-disabled:after {
    content: '';
    position: absolute;
    width: 42px;
    height: 2px;
    background: red;
    transform: rotate(-30deg);
    z-index: 995;
    left: -4px;
    top: 10px;
}

.swatch-option-disabled {
    outline: none !important;
    cursor: default;
    border: 0;
}

// Bugfix for Add To Cart button
.swatch-opt-listing {
    margin-bottom: 10px;
}

.swatch-option-loading {
    content: url('../images/loader.svg');
}

.swatch-input {
    left: -1000px;
    position: absolute;
    visibility: hidden;
}
