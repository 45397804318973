//
//  Variables
//  _____________________________________________

$checkout-summary__background-color : $color-white-smoke !default;
$checkout-summary__padding          : 22px $indent__l !default;

$checkout-summary-title__margin     : $indent__s !default;
$checkout-summary-mark-value__color : $color-gray60 !default;

$checkout-summary-items__max-height : auto !default;
$checkout-summary-items__padding    : 15px !default;

//
//  Order Summary
//  ---------------------------------------------

.opc-block-summary {
    @extend .abs-add-box-sizing;
    margin: 0 0 $indent__base;

    > .title {
        display: block;
        margin-bottom: 30px;
        font-size: 2rem;
        font-weight: 500;
        letter-spacing: 0.5px;
    }

    //  Totals table
    .table-totals {
        @extend .abs-sidebar-totals;
        margin-bottom: 60px;

        .mark {
            padding-left: 0;
        }

        .amount {
            padding-right: 0;
        }

        .grand.totals {

            .mark,
            .amount {
                font-size: 1.6rem;

                strong {
                    font-weight: 700;
                }
            }
        }

        .totals-tax-summary {
            position: relative;

            .amount,
            .mark {
                border: none;
            }

            .amount {
                .price {
                    padding-right: 0;

                    &:after {
                        display: none;
                    }
                }
            }

            .mark {
                span {
                    position: relative;
                    padding-right: 20px;

                    @include icomoon("simple-arrow-down", "before", true, true) {
                        font-size: 6px;
                        color: $c-dark;
                    }

                    &:before {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            &.expanded {
                .mark {
                    span {
                        position: relative;

                        &:before {
                            top: 50%;
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    .mark {
        .value {
            @include lib-css(color, $checkout-summary-mark-value__color);
            display: block;
        }
    }

    .grand.incl {

        & + .grand.excl {

            .mark,
            .amount {
                border-top: 0;
                @include lib-font-size(14);
                padding-top: 0;

                strong {
                    font-weight: $font-weight__regular;
                }
            }
        }
    }

    .not-calculated {
        font-style: italic;
    }

    //
    //  Items list
    //  ---------------------------------------------

    //  Block title
    .block.items-in-cart {

        > .title {
            cursor: pointer;
            @include icomoon("arrow-down", "before", true, true) {
                font-size: 8px;
                color: $c-dark;
            }

            margin-bottom: 25px;
            position: relative;

            &:before {
                position: absolute;
                right: 0;
                top: 7px;
            }

            &:after {
                display: none;
            }

            strong span {
                font-size: 1.4rem;
                font-weight: 600;
                margin: 0;
            }
        }

        &.active {
            > .title {
                &:before {
                    transform: rotate(180deg);
                }
            }
        }

        .product {
            position: relative;
        }
    }

    //  Cart items
    .minicart-items-wrapper {
        @include lib-css(max-height, $checkout-summary-items__max-height);
        margin: 0;
        padding: 0;
        border: 0;

        .minicart-items {
            .product-item-details {
                .options.list {

                    .label, .values {
                        color: #000000;
                        font-size: 12px;
                        letter-spacing: 0;
                        line-height: 15px;
                        display: inline-block;
                        font-weight: normal;
                        padding: 0;
                        margin: 0;
                    }

                    .values {
                        min-width: 25%;
                    }
                }
            }

            .details-qty {
                .label {
                    font-weight: normal;
                }
            }
        }
    }

    .column.main & {

        .product-item {
            margin: 0;
            padding: 15px 0;
            max-width: 100%;

            &:not(:first-child) {
                border-top: none;
            }

            &-name-block {
                vertical-align: top;
            }
            &-name {
                padding: 0 0 7px 0;
                font-size: 12px;
            }

            &-details {
                padding-left: 20px;

                .price-including-tax,
                .price-excluding-tax {
                    margin-top: 0;
                }
            }

            &-inner {
                @include max-screen($screen__l) {
                    padding-top: 0;
                }
            }
        }

        .product.options {
            margin-top: 0;

            .item-options {
                margin-top: 0;

                .label {
                    font-weight: normal;
                }

                li {
                    color: $c-dark;
                    margin-bottom: 0;
                }
            }
        }
    }

    .product-item {
        font-size: 1.2rem;



        .product-item-inner {
            display: table;
            width: 100%;
        }

        .product-item-name-block {
            display: table-cell;
            padding: 0 10px 0 0;
            text-align: left;
            margin: 0;
        }

        .product-item-name {
            margin: 0;
        }

        .subtotal {
            display: table-cell;
            text-align: right;
        }

        .price {
            font-size: 1.2rem;
            font-weight: $font-weight__regular;
        }

        .price-including-tax {

            & + .price-excluding-tax {
                margin: 0;

                .price {
                    font-size: 1.2rem;
                }
            }
        }
    }

    .actions-toolbar {
        position: relative;
        z-index: 1;

        .secondary {
            border-top: $border-width__base solid $border-color__base;
            display: block;
            float: none;
            margin: -1px 0 0;
            padding: 15px 0 0;
            text-align: left;

            .action {
                margin: 0;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {

    .opc-summary-wrapper {

        .modal-header {

            .action-close {
                display: none;
            }
        }
    }
}

@include max-screen($screen__l) {

    .opc-block-summary {

        > .title {
            text-align: center;
        }
    }
}

