
.opc-sidebar {
    width: 100%;
    max-width: 335px;
    margin-top: 50px;
    float: right;
}

@include max-screen($screen__l) {

    .opc-sidebar {
        margin-top: 0;
        max-width: none;
        left: 0;

        .modal-inner-wrap {
            padding: 30px;
            transform: none;
        }
    }
}

