//
//  Components -> Modals
//  _____________________________________________

//
//  Variables
//  ---------------------------------------------

$modal-title__color                   : $text__color !default;
$modal-title__border                  : 1px solid $color-gray-light5 !default;

$modal-popup-title__font-size         : 26px !default;
$modal-popup-title-mobile__font-size  : $font-size__base !default;

$modal-slide__first__indent-left      : 44px !default;
$modal-slide-mobile__background-color : $color-gray-light01 !default;
$modal-overlay__background-color      : fade($color-gray20, 55%) !default;

$modal-action-close__color            : $c-dark !default;
$modal-action-close__font-size        : 32px !default;
$modal-action-close__hover__color     : darken($c-dark, 10%) !default;

$modal-slide-action-close__padding    : 0 !default;

//
//  Common
//  _____________________________________________

.modal-custom,
.modal-popup,
.modal-slide {
    .action-close {
        @include lib-button-reset();
        @include icomoon("cross", "before", true, true) {
            font-size: 14px;
            color: #000;
        }
        position: absolute;
        right: 27px;
        top: 27px;

        span {
            display: none;
        }

        &:hover {
            &:before {
                color: $modal-action-close__hover__color;
            }
        }
    }
}


.modal-popup {
    pointer-events: none;

    .modal-title {
        @include lib-css(font-weight, $font-weight__light);
        @include lib-css(padding-bottom, $indent__s);
        font-size: 2.6rem;
        font-weight: 500;
        min-height: 1em;
        word-wrap: break-word;
        text-align: center;
        margin-bottom: 40px;
        padding-top: 27px;
    }

    .action-close {
        padding: 0;
    }

    &.confirm {
        .modal-content {
            padding: 60px 30px 30px 30px;
        }

        .modal-footer {

            @media screen and (max-width: 540px) {
                display: flex;
                flex-direction: column-reverse;
            }

            .action-dismiss {
                @extend .primary--border;
            }

            .action-accept {
                @extend .primary--full;
                padding: 16px 53px;

                @media screen and (max-width: 540px) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &.popup-authentication {
        @media screen and (max-width: $screen__m) {
            .modal-inner-wrap {
                transform: inherit;
                width: 100%;
            }
        }
    }
}

.modal-slide {

    .action-close {
        padding: $modal-slide-action-close__padding;
    }

    .page-main-actions {
        margin-bottom: calc(#{$modal-slide-header__padding-vertical} - #{$indent__l / 2});
        margin-top: $modal-slide-header__padding-vertical;
    }
}

.modals-overlay {
    background-color: rgba($c-dark, 0.9);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
}

body {

    &._has-modal-custom {

        .modal-custom-overlay {
            height: 100vh;
            left: 0;
            position: fixed;
            top: 0;
            width: 100vw;
            z-index: $overlay__z-index;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__l) {

    .custom-slide {
        @include lib-modal();
        @include lib-modal-slide();

        &._show {
            -webkit-overflow-scrolling: touch;
            overflow-x: hidden;
            overflow-y: auto;
        }

        .modal-inner-wrap {
            background-color: $c-white;
            box-sizing: border-box;
            height: auto;
            min-height: 100%;
        }
    }

    body {

        &._has-modal-custom {
            height: 100vh;
            overflow: hidden;
            width: 100vw;

            .modal-custom-overlay {
                @include lib-css(background-color, $modal-overlay__background-color);
            }
        }
    }

    .modal-popup {

        &.modal-slide {

            &._inner-scroll {
                width: 100%;

                &._show {
                    -webkit-overflow-scrolling: touch;
                    overflow-y: auto;
                }

                .modal-inner-wrap {
                    height: auto;
                    min-height: 100%;
                }
            }
        }
    }
}
