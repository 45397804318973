//
//  Checkout Estimated Total
//  ---------------------------------------------

.opc-estimated-wrapper {
    @extend .abs-no-display-desktop;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px -30px 0 -30px;
    padding: 18px 30px;
    background-color: $c-pale-grey;

    @media screen and (max-width: $screen__l) {
        margin: 30px -20px;

        .minicart-wrapper {
            margin-top: 0;
        }
    }

    .estimated-block {
        float: left;

        .estimated-label {
            display: block;
            position: relative;

            @include icomoon("arrow-down", "before", true, true) {
                font-size: 8px;
                color: $c-dark;
            }

            &:before {
                position: absolute;
                right: -20px;
                top: 8px;
            }

            @media screen and (max-width: $screen__m) {
                &:before {
                    padding-left: 15px;
                }
            }
        }
    }

    .minicart-wrapper {
        @include icomoon("basket", "before", true, true) {
            color: $c-black;
            font-size: 20px;
        }

        display: flex;
        align-items: center;

        .estimated-price {
            margin-left: 5px;
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {

    .opc-estimated-wrapper {
        display: none;
    }
}
