$product-grid-items-per-row-layout-default          : 2 !default;

$product-grid-items-per-row-layout-1-screen-s       : 3 !default;
$product-grid-items-per-row-layout-1-screen-m       : 4 !default;
$product-grid-items-per-row-layout-1-screen-l       : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s  : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m  : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l  : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l : '' !default;

$product-grid-items-per-row-layout-3-screen-s       : 3 !default;
$product-grid-items-per-row-layout-3-screen-m       : '' !default;
$product-grid-items-per-row-layout-3-screen-l       : '' !default;

$product-grid-items-padding                         : 0 $indent__base $indent__base !default;
$product-grid-items-margin                          : 0 0 $indent__s !default;

$product-name-text-decoration                       : none !default;
$product-name-text-decoration-hover                 : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                        : 26px !default;
$product-h1-margin-bottom-desktop                   : $indent__base !default;
$product-page__max-width: 1440px !default;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------
.old-price,
.old.price {
    .price-wrapper {
        .price {
            text-decoration: line-through;
        }
    }
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child:after {
                    content: ')';
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    font-weight: $font-weight__bold;
}

.product.name a {
    @extend .abs-product-link;
}

.category-image {
    .image {
        display: block;
        height: auto;
        max-width: 100%;
    }
}

.category-image,
.category-description {
    margin-bottom: $indent__base;
}

//
//  Product images general container
//  ---------------------------------------------

.product-image-container {
    display: inline-block;
    max-width: 100%;
}

.product-image-wrapper {
    display: block;
    height: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

.product-image-photo {
    bottom: 0;
    display: block;
    height: auto;
    left: 0;
    margin: auto;
    max-width: 100%;
    position: absolute;
    right: 0;
    top: 0;
}

//
//  Product view
//  ---------------------------------------------

.product.media {
    .product.photo .photo.image {
        @extend .abs-adaptive-images-centered;
    }

    .placeholder .photo.container {
        max-width: 100%;
    }

    .notice {
        @include lib-css(color, $text__color__muted);
        @include lib-font-size($font-size__s);
        margin: $indent__s 0;
    }

    .product.thumbs {
        margin: $indent__base 0 $indent__l;
    }

    .items.thumbs {
        @include lib-list-inline();

        .active {
            display: block;
            line-height: 1;
        }
    }
}

.product-contact-options {
    margin: 140px 0;

    @media screen and (max-width: $screen__m) {
        margin: 80px 0;
    }

    .title {
        font-size: 32px;
        letter-spacing: 0.5px;
        text-align: center;
        margin-bottom: 50px;

        @media screen and (max-width: $screen__m) {
            display: none;
        }
    }

    .advantages {
        margin: 0;

        .advantage {
            cursor: pointer;

            @media screen and (max-width: $screen__m) {
                width: 100%;
                margin: 25px 0;
            }
        }
    }
}

.product-lifestyle-image {
    margin-top: 132px;
    margin-left: -$new-layout-padding;
    margin-right: -$new-layout-padding;

    @media screen and (max-width: $screen__m) {
        margin-left: 0;
        margin-right: 0;
    }
}

.additional-attributes-wrapper {
    max-width: 1100px;
    margin: 100px auto 0 auto;
    width: 100%;
    padding-bottom: 20px;

    @media screen and (max-width: $screen__m) {
        box-sizing: border-box;
        padding: 0 17px;
        margin: 90px auto 0 auto;
    }

    .title {
        text-align: center;
        margin-bottom: 60px;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 30px;
        line-height: 41px;
        letter-spacing: 0.1em;

        @media screen and (max-width: $screen__m) {
            font-size: 20px;
            line-height: 27px;
        }
    }

    .additional-attributes {
        .inner-block {
            @include lib-vendor-prefix-display(flex);
            padding: 40px 0;
            border-top: solid 1px $c-pale-lilac;

            @media screen and (max-width: $screen__m) {
                flex-direction: column;
                padding: 30px 0;
            }

            &:last-child {
                border-bottom: solid 1px $c-pale-lilac;
            }

            .label {
                max-width: 240px;
                width: 100%;
                font-size: 14px;
                line-height: 24px;

                @media screen and (max-width: $screen__m) {
                    margin-bottom: 20px;
                }
            }

            .label,
            .data {
                font-size: 14px;
                line-height: 24px;
                letter-spacing: normal;
            }
        }

        .action {
            border-top: solid 1px $c-pale-lilac;
            padding-top: 27px;
            display: block;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: normal;
            text-align: center;
            position: relative;

            @include icomoon("arrow-down", "before", true, true) {
                font-size: 6px;
                color: $c-black;
            }

            &:before {
                position: absolute;
                bottom: -10px;
                left: 50%;
                transform: translateX(-50%);
            }

            &:hover {
                text-decoration: none;
            }

            &.less {
                &:before {
                    transform: translateX(-50%) rotate(180deg);
                }
            }
        }
    }
}

.catalog-product-view {
    .product-video-section {
        position: unset;
        max-width: 1000px;
        margin: 100px auto 0 auto;

        @media screen and (max-width: $screen__l) {
            margin: 90px auto 0 auto;
        }

        &-title {
            text-align: center;
            margin-bottom: 60px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 30px;
            line-height: 41px;
            letter-spacing: 0.1em;

            @media screen and (max-width: $screen__l) {
                margin-bottom: 40px;
                font-size: 20px;
                line-height: 27px;
            }
        }

        &-content {
            display: grid;
            grid-gap: 15px;
            grid-template-columns: repeat(auto-fit, minmax(323px, 1fr));
            place-items: center;

            @media screen and (max-width: $screen__m) {
                grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
            }
        }

        &-wrapper {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
    .company-values {
        @media screen and (max-width: $screen__m) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    .appointment {
        max-width: $product-page__max-width;
        margin-top: 160px;

        @media screen and (max-width: $screen__m) {
            margin: 50px 20px 0 20px;

            .inner-block {
                .banner-block {
                    background-position: center !important;
                }
            }
        }
    }
}

.block.related,
.block.upsell,
.block.widget.block-viewed-products-grid,
.block.widget.block-products-list {
    position: relative;
    max-width: 1236px;
    margin: 0 auto;

    @media screen and (max-width: $screen__m) {
        margin: 95px auto 0 auto;
        padding: 0 20px;
    }

    .block-title {
        margin-bottom: 40px;
        margin-left: 0;

        @media screen and (max-width: $screen__m) {
            margin: 0 auto;
            text-align: center;
        }

        strong {
            font-size: 22px;
            font-weight: normal;
            line-height: 30px;

            @media screen and (max-width: $screen__m) {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                letter-spacing: 0.12em;
                text-transform: uppercase;
            }

            &:after {
                content: '...';
            }
        }
    }

    .products-grid {
        .product-item {
            width: 100%;

            .product-item-details {
                display: block;
                margin-bottom: 0;

                .price-box {
                    padding-bottom: 0;
                }
            }

            &-name {
                @include lib-vendor-prefix-display(flex);
                justify-content: space-between;
                width: 100%;

                .product-item-link {
                    width: 80%;
                }

                .actions-secondary {
                    .action.tocompare {
                        display: none;
                    }
                }
            }

            .price-box {
                .price-label {
                    display: none;
                }
            }

            .price-including-tax .price, .price-excluding-tax .price {
                font-weight: normal;
            }

            .product-item-description {
                margin: 0;
            }

            .product-brand-name {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                text-transform: uppercase;
                padding: 0;
            }
        }

        .owl-carousel {
            position: unset;

            .owl-item {
                max-width: 325px;
                margin-right: 9px;

                .product-item {
                    margin: 0;
                    padding: 0;
                    max-width: 325px;
                    width: 100%;

                    @media screen and (max-width: $screen__m) {
                        max-width: 156px;
                        margin: 0;
                    }
                }

            }
        }
    }

    .owl-theme {
        .owl-stage-outer,
        .owl-stage {
            position: unset;
        }

        .owl-nav {
            position: absolute;
            right: 0;
            top: 0;

            .owl-prev {
                margin: 0 15px 0 0;
            }

            .owl-next {
                margin: 0 0 0 15px;
                border-radius: 0;
            }

            [class*='owl-']:hover,
            [class*='owl-']:focus {
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
}

.block.upsell {
    margin-top: 90px;
}

.product.info.detailed {
    clear: both;
    margin-bottom: 30px;

    .additional-attributes {
        width: auto;
        @include lib-table-resize(
            $_th-padding-left   : 0,
            $_th-padding-right  : $indent__l,
            $_th-padding-bottom : $indent__s,
            $_td-padding-bottom : $indent__s
        );
    }
}

.product-info-main {
    position: relative;

    .page-title-wrapper {

        @media screen and (max-width: $screen__m) {
            display: block;
            vertical-align: top;
            margin-bottom: 20px;
        }

        .page-title {
            margin-bottom: 0;
            font-weight: normal;
            letter-spacing: 1px;
            font-size: 20px;
            line-height: 28px;
        }

        .amshopby-option-link {
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-transform: uppercase;
        }
    }

    .short-description {
        margin-bottom: 33px;

        p {
            margin: 0;
        }
    }

    .stock {

        &.available,
        &.unavailable {
            display: inline-block;
            font-weight: $font-weight__bold;
            margin-right: $indent__base;
            text-transform: uppercase;
            vertical-align: top;
        }
    }

    .product {

        &.attribute {

            &.sku {
                color: $c_grey04;
                text-transform: uppercase;

                &:before {
                    content: "#";
                }

                .value {
                    display: inline-block;
                }

                .type {
                    display: none;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .product-reviews-summary .reviews-actions {
        @include lib-font-size($font-size__base);
    }

    .action.mailto.friend {
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 18px 0;
        margin-bottom: 0;
        font-size: 18px;
        line-height: 25px;
        border-bottom: 1px solid #e9e9eb;
        cursor: pointer;

        @include icomoon("share", "after", true, true) {
            font-size: 20px;
            color: $c-black;
        }

        &:before {
            display: none;
        }
    }

    .box-tocart {
        flex: 1;
        width: 100%;

        .fieldset {
            @include lib-vendor-prefix-display(flex);

            @media screen and (max-width: $screen__m) {
                flex-direction: column;
            }

            .qty {
                max-width: 110px;

                @media screen and (max-width: $screen__m) {
                    max-width: 100%;
                }

                .label {
                    display: none;
                }

                .qty-control {
                    border: none;
                    box-shadow: 0 0 0 1px $c-dark inset;
                    background: $c-white;
                    border-radius: 1px;
                    color: $c-dark;
                    height: auto;
                    @media screen and (max-width: $screen__m) {
                        font-size: 24px;
                    }

                    .input-group {
                        @include lib-vendor-prefix-display(flex);
                        align-items: center;
                        height: 50px;
                        @media screen and (max-width: $screen__m) {
                            height: 61px;
                        }

                        &__addon {
                            @media screen and (max-width: $screen__m) {
                                width: 25%;
                            }
                        }

                        button,
                        &-button {
                            @extend .button-as-link;

                            &:active {
                                background: none;
                                box-shadow: none;
                            }
                        }

                        &__button {
                            &--decrease {
                                @media screen and (max-width: $screen__m) {
                                    width: 100%;
                                    text-align: right;
                                }

                            }

                            &--increase {
                                @media screen and (max-width: $screen__m) {
                                    width: 100%;
                                    text-align: left;
                                }

                            }
                        }

                        &__icon {
                            &--decrease {
                                &:before {
                                    content: '\2212';
                                    display: inline-block;
                                    color: $c-dark;
                                    font-size: 16px;
                                    line-height: 20px;
                                    padding-left: 20px;
                                    height: 22px;
                                    @media screen and (max-width: $screen__m) {
                                        font-size: 24px;
                                    }
                                }
                            }

                            &--increase {
                                &:before {
                                    content: '\002B';
                                    display: inline-block;
                                    color: $c-dark;
                                    font-size: 16px;
                                    line-height: 22px;
                                    padding-right: 20px;
                                    @media screen and (max-width: $screen__m) {
                                        font-size: 24px;
                                        line-height: 14px;
                                    }
                                }
                            }
                        }

                        &__input {
                            border-top: solid 1px $c-dark;
                            border-bottom: solid 1px $c-dark;
                            border-left: unset;
                            border-right: unset;
                            box-shadow: none;
                            padding: 15px 0;
                            text-align: center;
                            border-radius: 0;
                            font-size: 16px;
                            line-height: 22px;
                            height: 50px;
                            @media screen and (max-width: $screen__m) {
                                width: 50%;
                                border: none;
                            }
                        }
                    }
                }
            }

            .actions {
                flex: 1;

                @media screen and (max-width: $screen__m) {
                    margin-top: 17px;
                }
            }
        }
    }

    .product-brand-name {
        margin-bottom: 0;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase;
        padding: 0;
    }
}

.page-product-configurable {
    .page-title-wrapper {
        @media screen and (max-width: $screen__m) {
            margin-bottom: 0;
        }
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {

        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {

            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {

            .legend {

                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size   : $form-field-label-asterisk__font-size,
                        $_color       : $form-field-label-asterisk__color,
                        $_font-family : $form-field-label-asterisk__font-family,
                        $_font-weight : $form-field-label-asterisk__font-weight,
                        $_line-height : $form-field-label-asterisk__line-height,
                        $_font-style  : $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        @include lib-vendor-prefix-display();
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $screen__m) {
            display: block;
        }

        &:not(:last-of-type) {
            margin-bottom: 30px;

            @media screen and (max-width: $screen__m) {
                margin-bottom: 40px;
            }
        }

        &:last-of-type {
            margin: 0;
        }

        &:before,
        &:after {
            display: none;
        }

        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }

        .label {
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;

            @media screen and (max-width: $screen__m) {
                padding-bottom: 6px;
            }

            span {
                &:after {
                    content: ':';
                }
            }
        }

        .control {
            min-width: 70%;
        }
    }
}

.product-info-main,
.product-options-bottom {

    @media screen and (max-width: $screen__m) {
        .product-info-price {
            display: block;
            vertical-align: top;
            text-align: left;
        }
    }

    .price-box {
        margin: 20px 0;
        @include lib-vendor-prefix-display(flex);
        flex-direction: row-reverse;
        justify-content: flex-end;

        @media screen and (max-width: $screen__m) {
            margin: 31px 0 46px;
        }

        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            font-size: 25px;
            line-height: 28px;

            .price {
                font-size: 25px;
                line-height: 28px;
            }
        }

        .price-wrapper .price {
            font-size: 25px;
            line-height: 28px;
        }

        .price {
            white-space: nowrap;
        }
    }

    .normal-price,
    .special-price {
        display: block;
        padding-left: 20px;

        .price-container {
            @include lib-font-size(14);
        }

        .price {
            color: $c-strawberry;
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .old-price,
    .special-price {
        .price-label {
            &:after {
                content: ': ';
            }
        }
    }

    .box-tocart {
        margin: 0;

        .field.qty {
            padding-right: 0.75 * $indent__base;
            margin-bottom: 0;

            @media screen and (max-width: $screen__m) {
                padding-right: 0;
            }
        }

        .input-text.qty {
            $tocart-input-size: $button__line-height__l + 30px;
            height: 50px;
            text-align: center;
            width: $tocart-input-size;
            border: solid 1px $c-dark;
            font-size: 16px;
            line-height: 22px;
            border-radius: 1px;

            @media screen and (max-width: $screen__m) {
                width: 100%;
            }
        }

        .actions {
            text-align: center;
        }

        .action {
            width: 100%;
            box-sizing: border-box;
            @extend .primary--full;
            @media screen and (max-width: $screen__m) {
                height: 53px;
            }
        }
    }

    .product-addto-links {
        margin: 19px 0 40px 0;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: $screen__m) {
            display: none;
        }
    }
}

.page-product-configurable {
    .product-info-main,
    .product-options-bottom {
        .normal-price {
            padding-left: 0;

            .price {
                color: $c-dark;
            }

            &.as-special-price {
                padding-left: 20px;

                .price {
                    color: $c-strawberry;
                }
            }
        }
    }
}

.product-options-bottom {
    @include lib-vendor-prefix-display(flex);
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 40px 0 20px 0;

    @media screen and (max-width: $screen__m) {
        flex-direction: column;
        margin: 15px 0 49px 0;
    }

    .box-tocart {
        flex: 1;
        width: 100%;

        .fieldset {
            @include lib-vendor-prefix-display(flex);

            @media screen and (max-width: $screen__m) {
                flex-direction: column;
            }

            .qty {
                .label {
                    display: none;
                }
            }

            .actions {
                flex: 1;

                @media screen and (max-width: $screen__m) {
                    margin-top: 17px;
                }
            }
        }
    }
}

.product-inventory-available {
    width: 100%;

    @media screen and (max-width: $screen__m) {
        text-align: center;
    }

    .action {
        @extend .button-as-link;
        color: $c_grey04;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: normal;
        text-transform: none;
        margin-bottom: 20px;
        text-decoration: underline;
        cursor: pointer;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    @include lib-css(background, $sidebar__background-color);
    margin: $indent__s 0;
    padding: $indent__s (0.75 * $indent__base);

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size(14);
            font-weight: $font-weight__bold;
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block.widget.block-viewed-products-grid {
    .product-items {
        @media screen and (max-width: $screen__m) {
            margin: 30px 0;
        }
    }

    .product-item-details {
        @media screen and (max-width: $screen__m) {
            margin: 0;
        }
    }

    .owl-dots {
        margin-top: -20px;
    }
}

.block.widget.block-viewed-products-grid,
.block.related,
.block.upsell,
.block.widget.block-products-list{
    .action.select {
        margin: 0 $indent__xs;
    }
}

.product-info-more {
    margin-top: 54px;

    .short-description {
        padding-left: 10px;
        font-size: 14px;
        line-height: 1.71;
    }
}

.product-collapsible-info {
    margin-top: 50px;

    ul {
        padding-left: 0;
    }

    &__item {

        .title {
            @include icomoon("arrow-down", "after", true, true) {
                font-size: 8px;
                color: $c-dark;
            }
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 18px 0;
            margin-bottom: 0;
            font-size: 18px;
            line-height: 25px;
            border-top: 1px solid $c-pale-lilac;
            cursor: pointer;
        }

        .content {
            margin: 0 0 20px 0;
            
            table {
                width: 100% !important;
            }

            h2 {
                font-size: 14px;
                margin: 0 0 10px 0;
            }

            ul {
                padding-left: 35px;
                list-style: disc;

                li {
                    line-height: normal;
                }
            }

            .grey-bg {
                background: #f2f2f2;
                padding: 20px;
                margin: 20px 0;
            }

            &.delivery-options {
                p {
                    margin-bottom: 10px;
                }
            }
        }

        &.active {

            .title {

                &:after {
                    transform: rotate(180deg);
                }
            }
        }

        &:last-of-type {
            border-bottom: 1px solid $c-pale-lilac;
        }
    }
}

.product-description {
    max-width: 880px;
    margin: 40px auto 80px auto;

    @media screen and (max-width: $screen__m) {
        padding: 0 20px;
    }

    &-title {
        font-weight: 600;
        font-size: 30px;
        line-height: 41px;
        text-align: center;
        padding-bottom: 40px;
        text-transform: uppercase;
        letter-spacing: 0.1em;

        @media screen and (max-width: $screen__m) {
            font-size: 20px;
            line-height: 27px;
        }
    }

    &-text {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
    }
}

.size-guide-modal.modal-popup {

    .modal-title {
        margin-bottom: 0;
    }

    .modal-content {
        padding-bottom: 30px;
    }
}

//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {

    .product-items {

        .product-item {
            margin-bottom: $indent__base;
            position: relative;
        }

        .product-item-info {
            position: relative;
            width: auto;

            .product-item-photo {
                left: 0;
                position: absolute;
                top: 0;
            }
        }

        .product-item-name {
            margin-top: 0;
        }

        .product-item-details {
            margin: 0 0 0 85px;
        }

        .product-item-actions {
            display: block;
            margin-top: $indent__s;
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size(12);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee:before {
                display: inline-block;
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {

    .catalog-product-view {

        .page-main {
            padding: 0;
        }
    }

    .product-info-main {
        padding: 35px 20px 0 20px;
        margin-bottom: 0;

        .box-tocart {

            .actions {

                .action.tocart {
                    @extend .abs-button-responsive-smaller;
                }
            }
        }

    }

    .block.widget.block-viewed-products-grid,
    .block.related,
    .block.upsell,
    .block.widget.block-products-list {

        .action.select {
            display: block;
            margin: $indent__xs 0;
        }
    }

    .compare,
    .product-addto-links .action.tocompare,
    .product-item-actions .actions-secondary > .action.tocompare,
    [class*='block-compare'] {
        display: none;
    }


    .product-media__list {
        position: relative;

        .product-media__image {
            display: none;

            &:first-of-type {
                display: block;
            }
        }

        &.loader {

            .product-media__image {
                display: block;
            }
        }

        .owl-dots {
            position: absolute;
            bottom: 0;
            width: 100%;
            padding: 20px 0;

            .owl-dot {

                span {
                    background-color: $c-black;
                    opacity: 0.5;
                    width: 6px;
                    height: 6px;
                    transition: opacity 0.24s ease, transform 0.24s ease;
                }

                &.active {

                    span {
                        background-color: $c-black;
                        opacity: 1;
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}

.product-add-form {
    @extend .abs-revert-field-type-desktop;
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {

    .sidebar {

        .product-items {

            .product-item-info {

                .product-item-photo {
                    float: none;
                    left: 0;
                    margin: 0;
                    position: absolute;
                    top: 0;
                }
            }

            .product-item-details {
                margin-left: 85px;
            }
        }
    }
}

//
//  Product page layout
//  ---------------------------------------------

@include min-screen($screen__l) {

    .product-top {
        max-width: $product-page__max-width;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .product-info-main {
            width: 100%;
            max-width: 486px;
            padding: 45px 0 60px;
        }

        .product-media {
            width: 100%;
            max-width: 700px;
            box-sizing: border-box;
            position: relative;
            padding-top: 45px;

            .product-label {
                border-radius: 2px;
                background-color: $c-strawberry;
                padding: 0 12px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 28px;
                letter-spacing: normal;
                text-align: center;
                color: $c-white;
                position: absolute;
                top: 70px;
                left: 85px;
                z-index: 2;
                text-transform: lowercase;
            }
        }
    }
}

@include max-screen($screen__l) {
    .product-top {
        .product-media {
            width: 100%;
            max-width: 700px;
            box-sizing: border-box;
            position: relative;
            margin: 0 auto;
            padding: 0 20px;

            .product-label {
                border-radius: 2px;
                background-color: $c-strawberry;
                padding: 0 12px;
                font-size: 14px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 28px;
                letter-spacing: normal;
                text-align: center;
                color: $c-white;
                position: absolute;
                top: 45px;
                left: 25px;
                z-index: 9;
                text-transform: lowercase;
            }
        }
    }
}
