.cms-index-index {
    .page-title-wrapper {
        display: none;
    }
}
// APPOINTMENT SECTION
.appointment {
    max-width: $layout__max-width - 80px;
    margin: 0 auto;

    @media screen and (max-width: $screen__m) {
        margin-left: -20px;
        margin-right: -20px;
    }

    .inner-block {
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $screen__m) {
            flex-wrap: wrap;
            width: 100%;
        }

        .banner-block {
            width: 50%;
            @include lib-vendor-prefix-display(flex);
            justify-content: center;
            align-items: center;
            flex-direction: column;

            @media screen and (max-width: $screen__m) {
                flex-wrap: wrap;
                width: 100%;

                &:not(:first-child) {
                    padding-top: 120px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }

            .color-white {
                color: $c-white;
            }

            .color-black {
                color: $c-dark;
            }

            .subtitle {
                font-size: 14px;
                letter-spacing: 0.5px;
                text-align: center;
                text-transform: uppercase;
            }

            .title {
                font-size: 32px;
                letter-spacing: 0.5px;
                text-align: center;
            }

            .action.primary {
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 1.5px;
                text-align: center;
                border: solid 1px $c-white;
                background: transparent;
                color: $c-white;
                margin: 20px auto;
                text-transform: uppercase;
                display: inline-block;
                padding: 18px 54px;
                border-radius: 0;

                &.color-black {
                    border: solid 1px $c-dark;
                    color: $c-dark;
                }
            }

            .column {
                @include lib-vendor-prefix-display(flex);
                justify-content: center;
                align-items: center;
                flex-direction: column;
                position: relative;

                &:not(:last-child):after {
                    content: '';
                    width: 94px;
                    height: 1px;
                    background: #979797;
                    margin: 22px 0 37px 0;
                }
                .text {
                    margin-top: 5px;
                    font-size: 14px;
                    line-height: 1.71;
                    letter-spacing: normal;
                    color: $c-dark;
                }
            }
        }
    }
}
// ADVANTAGES SECTION
.home-advantages,
.advantages {
    @include lib-vendor-prefix-display(flex);
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 140px 0;

    @media screen and (max-width: $screen__m) {
        margin: 100px 0;
    }

    .advantage {
        text-align: center;
        margin: 0 70px;
        max-width: 225px;
        width: 100%;

        @media screen and (max-width: $screen__m) {
            margin: 40px 0;
            width: 50%;
            box-sizing: border-box;
        }

        .icon {
            &:before {
                font-size: 66px;
            }
        }

        .text {
            margin-top: 22px;
            font-size: 14px;
            line-height: 1.71;
            text-align: center;
            color: $c-dark;
        }

        &:hover {
            text-decoration: none;
        }
    }
}
// MAP SECTION
.home-map {
    margin-left: -$new-layout-padding;
    margin-right: -$new-layout-padding;
    position: relative;

    @media screen and (max-width: $screen__m) {
        margin-left: -20px;
        margin-right: -20px;
    }

    iframe {
        max-width: 100%;
    }

    .inner-block {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 9;

        @media screen and (max-width: $screen__m) {
            padding: 0 20px;
        }

        .title {
            display: block;
            font-size: 60px;
            letter-spacing: 0.25px;
            text-align: center;
            color: $c-dark;

            @media screen and (max-width: $screen__m) {
                font-size: 32px;
                line-height: 1.06;
                letter-spacing: 0.5px;
            }
        }

        .action {
            margin-top: 40px;
            border-radius: 1px;
            background-color: $c-dark;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.5px;
            text-align: center;
            color: $c-white;
            text-transform: uppercase;
            padding: 17px 15px;
            border: none;
        }

    }
}
