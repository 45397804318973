.product-attachments-wrapper {
    margin: auto;
    max-width: 1100px;
    width: 100%;
    @include lib-vendor-prefix-display(flex);
    justify-content: center;

    @media screen and (max-width: $screen__m) {
        flex-direction: column;
    }

    p {
        margin: 0;
    }

    .inner-block {
        flex: 1;
        text-align: center;
        margin: 104px auto 0 auto;

        @media screen and (max-width: $screen__m) {
            flex-direction: column;
            margin: 90px auto 0 auto;
        }
        
        &:not(:last-child) {
            border-right: solid 1px #979797;

            @media screen and (max-width: $screen__m) {
                border-right: none;
                margin-bottom: 40px;
            }
        }

        .title {
            font-size: 30px;
            font-weight: 600;
            letter-spacing: 0.1em;
            margin-bottom: 47px;
            text-align: center;
            line-height: 41px;
            text-transform: uppercase;

            @media screen and (max-width: $screen__m) {
                font-size: 20px;
                line-height: 27px;
            }
        }

        .description {
            letter-spacing: normal;
            text-align: center;
            padding: 0 80px;
            font-size: 14px;
            line-height: 24px;

            @media screen and (max-width: $screen__m) {
                padding: 0 20px;
            }
        }

        .description,
        .icons-wrapper-block {
            max-width: 580px;
            margin: 0 auto;
            padding-bottom: 20px;
        }

        .icons-wrapper-block {
            @include lib-vendor-prefix-display(flex);
            justify-content: space-around;

            @media screen and (max-width: $screen__m) {
                flex-wrap: wrap;
                justify-content: space-between;
            }

            .icon-block {
                margin: 0 50px;

                @media screen and (max-width: $screen__m) {
                    margin: 25px 0;
                    flex-basis: 50%;
                }

                @media screen and (min-width: $screen__m) and (max-width: $screen__l) {
                    margin: 25px 20px;
                }

                .icon {
                    max-width: 40px;
                    display: block;
                    margin: 0 auto 15px auto;
                }

                .text {
                    font-size: 12px;
                    letter-spacing: 0.19px;
                    line-height: normal;
                    min-width: 85px;

                    @media screen and (max-width: $screen__m) {
                        margin-top: 10px;
                    }
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}
