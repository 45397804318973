//
//  Variables
//  _____________________________________________

$checkout-modal-popup__width: 660px !default;

.checkout-index-index {

    .modal-popup {

        .modal-content {
            padding: 30px;
        }

        .fieldset > .field {
            @include lib-form-field-type-revert($_type: block);
        }

        .field-tooltip {

            .field-tooltip-content {
                @extend .abs-checkout-tooltip-content-position-top;
            }
        }
    }

    .modal-footer {

        .action-save-address {
            @extend .primary--full;
        }

        .action-hide-popup {
            @extend .button-as-link;
            @extend .secondary--underline;
            margin-left: 15px;
            text-transform: capitalize;
            color: $c-bluey-grey;

            &:after {
                background-color: $c-bluey-grey;
            }
        }
    }
}

@include min-screen($screen__l) {

    .checkout-index-index {

        .modal-popup {

            .modal-inner-wrap {
                @include lib-css(margin-left, -($checkout-modal-popup__width/2));
                @include lib-css(width, $checkout-modal-popup__width);
                left: 50%;
                right: auto;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
