$icon-account: "\e900";
$icon-basket: "\e901";
$icon-facebook: "\e902";
$icon-garantie: "\e903";
$icon-garantie-2: "\e904";
$icon-garantie-3: "\e905";
$icon-instagram: "\e906";
$icon-location: "\e907";
$icon-long-arrow-left: "\e908";
$icon-long-arrow-right: "\e909";
$icon-search: "\e90a";
$icon-arrow-down: "\e90b";
$icon-heart: "\e90c";
$icon-simple-arrow-down: "\e90d";
$icon-exchange: "\e90e";
$icon-lock: "\e90f";
$icon-assistance: "\e910";
$icon-cross: "\e911";
$icon-share: "\e912";
$icon-question-mark: "\e913";

.icon-account:before {
    content: "\e900";
}
.icon-basket:before {
    content: "\e901";
}
.icon-facebook:before {
    content: "\e902";
}
.icon-garantie:before {
    content: "\e903";
}
.icon-garantie-2:before {
    content: "\e904";
}
.icon-garantie-3:before {
    content: "\e905";
}
.icon-instagram:before {
    content: "\e906";
}
.icon-location:before {
    content: "\e907";
}
.icon-long-arrow-left:before {
    content: "\e908";
}
.icon-long-arrow-right:before {
    content: "\e909";
}
.icon-search:before {
    content: "\e90a";
}
.icon-arrow-down:after,
.icon-arrow-down:before {
    content: "\e90b";
}
.icon-heart:before {
    content: "\e90c";
}
.icon-simple-arrow-down:before {
    content: "\e90d";
}
.icon-exchange:before {
    content: "\e90e";
}
.icon-lock:before {
    content: "\e90f";
}
.icon-assistance:before {
    content: "\e910";
}
.icon-cross:before {
    content: "\e911";
}
.icon-share:before,
.icon-share:after {
    content: "\e912";
}
.icon-question-mark:before,
.icon-question-mark:after {
    content: "\e913";
}
