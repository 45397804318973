//
//  Footer
//  ---------------------------------------------
.page-footer {
    background: $c_bg-grey;
}

.footer.content {
    max-width: 1440px;
    font-size: 1.4rem;
    margin: 0 auto;
    padding: 103px 102px 0;

    @media screen and (max-width: $screen__m) {
        margin: 0 auto;
    }

    @include max-screen($screen__l) {
        padding: 50px 20px 0 20px;
        margin: 50px auto 0 auto;
    }

    ul {
        @extend .abs-reset-list;
    }

    .footer__top {
        display: flex;
        justify-content: space-between;

        .logo {
            text-align: left;
            display: inline-block;
            float: none;
            margin: 0;
            width: 100%;
        }
    }

    .footer-info-wrapper {
        max-width: 375px;
        min-width: 200px;
        width: 100%;

        @include max-screen($screen__l) {
            margin: 0 auto;
            max-width: 100%;
            min-width: auto;
        }
    }

    .footer__content {

        p {
            margin-top: 10px;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.71;
            letter-spacing: normal;
            color: #1c2028;
        }
    }

    .footer__links {
        width: 100%;

        h3 {
            font-size: 1.4rem;
            color: $c-bluey-grey;
            font-weight: 500;
            line-height: 1.14;
            letter-spacing: 1px;
            position: relative;

            @include max-screen($screen__l) {
                padding: 0 45px;
                font-size: 20px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: 0.5px;
                color: $c-dark;
            }

            .arrow-down,
            .arrow-up {
                display: none;

                @include max-screen($screen__l) {
                    display: block;
                }
            }

            .arrow-down {
                @include icomoon("arrow-down", "before", true, true) {
                    font-size: 8px;
                    color: $c-dark;
                }
            }

            .arrow-up {
                @include icomoon("arrow-down", "before", true, true) {
                    font-size: 8px;
                    color: $c-dark;
                }
            }

            .arrow-down:before,
            .arrow-up:before {
                display: inline-block;
                position: absolute;
                right: 45px;
                top: 50%;
                transform: translateY(-50%);
            }

            .arrow-up:before {
                display: inline-block;
                transform: translateY(-50%) rotate(180deg);
            }
        }

        ul.footer__links-first-level {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
            padding-left: 237px;
            width: 100%;

            ul {
                margin-top: 20px;
                line-height: 2;
                padding-right: 15px;
                white-space: nowrap;

                @include max-screen($screen__l) {
                    padding: 0 45px;
                }
            }
        }
    }

    .footer__bottom {
        @include lib-vendor-prefix-display(flex);
        justify-content: space-between;
        align-items: center;
        margin-top: 119px;
        padding-bottom: 34px;
    }

    .footer__small-links {

        @media screen and (max-width: $screen__l) {
            padding: 0 40px;
        }

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            li {
                color: $c_grey04;

                &:not(:last-child) {
                    margin-right: 55px;

                    @include max-screen($screen__l) {
                        margin-right: 15px;
                    }
                }
            }
        }

        a {
            color: $c_grey04;
            white-space: nowrap;
            line-height: 2;
        }
    }

    .footer__social {
        padding: 20px 0 0 0;

        ul {
            display: flex;

            @include max-screen($screen__l) {
                justify-content: center;
            }

            a {
                padding: 0 15px;
                display: block;
                
                &:first-child {
                    padding-left: 0;
                }

                &.icon:before {
                    color: $c-black;
                    font-size: 16px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .copyright {
        padding: 0;
        color: $c_grey04;
        font-size: 14px;

        @include max-screen($screen__l) {
            text-align: center;
            padding: 50px 0 82px 0;
        }
    }

    @include max-screen($screen__l) {

        .footer__top {
            flex-direction: column;
        }

        .footer__content {
            max-width: none;
            text-align: center;

            .logo,
            .logo img {
                margin: 0 auto;
            }

            p {
                margin-top: 30px;
            }
        }

        .footer__links {

            @media screen and (max-width: $screen__l) {
                margin-top: 25px;
            }

            ul.footer__links-first-level {
                padding-left: 0;
                flex-wrap: wrap;
                justify-content: space-around;

                > li {
                    margin-top: 5px;

                    @media screen and (max-width: $screen__l) {
                        width: 100%;
                    }
                }
            }
        }

        .footer__bottom {
            flex-direction: column;
            margin-top: 60px;

            @media screen and (max-width: $screen__l) {
                margin-top: 48px;
            }
        }

        .footer__social {
            order: 10;
            margin-left: 0;
            margin-right: 0;
        }
    }
    
    .footer {
        &__recaptcha_policy {
            font-size: 14px;
            color: $c_grey04;
            padding: 0 20px;

            p {
                margin: 0;
            }

            @include max-screen($screen__l) {
                margin-top: 20px;
                text-align: center;
            }
        }
    }
}
