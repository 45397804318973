@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/icomoon.eot?6hd2y5');
    src:  url('../fonts/icomoon.eot?6hd2y5#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?6hd2y5') format('woff2'),
    url('../fonts/icomoon.ttf?6hd2y5') format('truetype'),
    url('../fonts/icomoon.woff?6hd2y5') format('woff'),
    url('../fonts/icomoon.svg?6hd2y5#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}


@mixin icomoon($icon, $position: "after", $font-size: false, $color: false) {
    // Pseudo-element properties
    &:#{$position} {
        @extend .icon-#{$icon};
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
        @content;
    }
}

[class*="icon-"].icon {
    &:before,
    &:after {
        font-family: 'icomoon';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: middle;
    }
}
