.form.send.friend {
    max-width: 540px;
    margin: auto;

    .fieldset.sender,
    .fieldset.recipients{

        .field {
            @include lib-vendor-prefix-display(flex);
            flex-direction: column;

            .label {
                width: auto;
                text-align: left;
                margin-bottom: 6px;
            }

            .control {
                width: 100%;
            }

            &.name,
            &.sender,
            &.email {
                display: inline-block;
                width: 49%;
                box-sizing: border-box;
                vertical-align: top;

                @include max-screen($screen__m) {
                    width: 100%;
                }
            }

            &.name,
            &.sender {
                margin-right: 2%;

                @include max-screen($screen__m) {
                    margin-right: 0;
                }
            }

        }
    }

    .fieldset.recipients {
        .fields.additional {
            @include lib-vendor-prefix-display(flex);
            flex-direction: column-reverse;

            @include max-screen($screen__m) {
                padding-bottom: 15px;
            }
        }

        .actions-toolbar {
            margin-top: 10px;

            .action {
                @extend .button-as-link;
                @extend .secondary--underline;

                text-transform: none;
                width: max-content;
                text-align: left;

                &.remove {
                    margin: 0;
                    top: -15px;

                    @include max-screen($screen__m) {
                        top: 0;
                    }
                }
            }
        }
    }

    > .actions-toolbar {
        @include lib-vendor-prefix-display(flex);
        align-items: center;

        .primary {
            .action {
                @extend .primary--full;
            }
        }

        .secondary {
            margin-left: 20px;
            .action {
                @extend .secondary--underline;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .form.send.friend {
        @extend .abs-add-fields-desktop;
    }
}
