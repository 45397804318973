.load.indicator {
    @include lib-loader();
    position: absolute;

    > span {
        display: none;
    }
}

.loading-mask {
    @include lib-loading-mask();
    background: rgba(255, 255, 255, 0.5);
    position: absolute !important;

    .loader {
        $loader-size: 38px;

        > img {
            @include lib-loading-mask();
            box-sizing: border-box;
            width: $loader-size;
            height: $loader-size;
            padding-left: $loader-size;
            background-image: url('../images/loader.svg');
            background-repeat: no-repeat;
            background-size: contain;
        }

        > p {
            display: none;
        }
    }
}

body {

    > .loading-mask {
        z-index: $loader-overlay__z-index;
    }
}

._block-content-loading {
    position: relative;
    text-align: center;
    padding-top: 30%;
}
