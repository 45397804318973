.store-detail {
    @media screen and (max-width: $screen__l) {
        margin: auto;
    }

    .cms-page-wrapper-width {
        max-width: 1130px;
        padding-left: 0;
        padding-right: 0;
        margin: auto;
    }

    &-top-banner {
        position: relative;
        border-radius: 3px;
        max-width: 1440px;
        margin: auto;

        @media screen and (max-width: $screen__l) {
            padding-top: 0;
            margin: 0 -20px;
        }

        img {
            display: block;
            border-radius: 3px;
        }
    }

    &-store-name {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        font-weight: 600;
        font-size: 50px;
        line-height: 47px;
        color: $c-white;

        @media screen and (max-width: $screen__l) {
            font-size: 25px;
            line-height: 35px;
            font-weight: normal;
        }
    }

    h4 {
        font-weight: 400;
        line-height: 30px;
        margin: 65px 0;
        text-align: center;
        font-size: 25px;

        @media screen and (max-width: $screen__l) {
            font-size: 18px;
            line-height: 22px;
            margin: 35px 0;
        }

    }

    &-store-info {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $c-white;
        box-shadow: 1px 4px 17px rgba(0, 0, 0, 0.08);
        border-radius: 3px;

        @media screen and (max-width: $screen__l) {
            flex-direction: column-reverse;
        }

        &-address {
            flex: 1;
            text-align: center;
            padding: 20px 0;
            box-sizing: border-box;

            @media screen and (max-width: $screen__l) {
                width: 100%;
                padding: 47px 15px;
            }

            .bold {
                font-weight: bold;
                font-size: 20px;
                line-height: 20px;
            }

            p {
                font-size: 16px;
                line-height: 24px;

                @media screen and (max-width: $screen__l) {
                    font-size: 15px;
                    line-height: 20px;
                }
            }

            a {
                @include black-link($icon: true, $color: $c-dark);
            }
        }

        &-map {
            border-radius: 3px;

            @media screen and (max-width: $screen__l) {
                width: 100%;
            }

            iframe {
                display: block;

                @media screen and (max-width: $screen__l) {
                    width: 100%;
                }
            }
        }
    }
}
