.checkout-cart-index {
    .column {
        &.main {
            .block {
                &.shipping {
                    .fieldset {
                        &.estimate {
                            [name="shippingAddress.country_id"],
                            [name="shippingAddress.region_id"],
                            [name="shippingAddress.city"] {
                                display: none;
                            }
                            [name="shippingAddress.postcode"] {
                                width: 100%;
                                padding: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
