//  Newsletter subscription
.block.newsletter {
    margin: 30px auto 0;
    max-width: 375px;
    width: 100%;
    padding: 0;
    box-sizing: border-box;

    .form.subscribe {
        width: 100%;
        position: relative;

        .actions {
            position: absolute;
            right: 0;
            bottom: 3px;

            .action.subscribe {
                background-color: transparent;
                border: none;
                color: $c-black;
                transition: transform 0.24s ease;
                padding: 7px 0;
                font-size: 0;
                text-align: right;

                @include icomoon("long-arrow-right", "before", true, true) {
                    font-size: 16px;
                    color: $c-black;
                }

                &:before {
                    float: right;
                }

                &:hover {
                    background-color: transparent;
                }

                &:focus {
                    box-shadow: none;
                }
            }
        }
    }

    .title {
        text-align: left;
        font-size: 20px;
        line-height: 25px;
        margin-top: 0;
        margin-bottom: 50px;

        span {
            font-style: italic;
        }

        @media screen and (max-width: $screen__m) {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: center;
        }
    }

    .fieldset {
        margin: 0;
        padding: 0;
    }

    .field {
        margin: 0;

        .control {
            display: block;

            input {
                box-shadow: none;
                border-bottom: 1px solid $c-black;
                border-top: none;
                border-left: none;
                border-right: none;
                border-radius: 0;
                position: relative;
                padding: 7px 45px 7px 0;
                background: transparent;
            }
        }
    }

    .label {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: normal;
        display: inline-block;
    }
}
