.block.widget {
    &.block-products-list {
        position: relative;
        max-width: 1236px;
        margin: 80px auto 100px;

        @media screen and (max-width: $screen__m) {
            margin: 80px auto;
            padding: 0;
        }

        .block-title {
            margin-bottom: 40px;

            @media screen and (max-width: $screen__m) {
                margin: 0 auto 40px auto;
            }

            strong {
                font-style: normal;
                font-weight: normal;
                font-size: 22px;
                line-height: 30px;

                @media screen and (max-width: $screen__m) {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                }
            }
        }

        .products-grid {
            .product-item {
                width: 100%;
            }

            .owl-carousel {
                position: unset;

                @media screen and (max-width: $screen__m) {
                    .owl-nav {
                        display: none;
                    }
                    
                    .owl-dots {
                        margin-top: 0;
                    }
                    
                    .owl-item .product-item {
                        max-width: 100%;
                    }
                }
            }
        }

        .owl-theme {
            .owl-stage-outer,
            .owl-stage {
                position: unset;
            }
        }
    }
}

