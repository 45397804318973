.form.form-orders-search {
    max-width: 540px;
    margin: auto auto 40px auto;

    @media screen and (max-width: $screen__m) {
        margin-bottom: 0;
    }

    .fieldset {
        margin-bottom: 0;

        .field {
            @include lib-vendor-prefix-display(flex);
            flex-direction: column;

            .label {
                width: auto;
                text-align: left;
                margin-bottom: 6px;
            }

            .control {
                width: 100%;
            }
        }
    }

    > .actions-toolbar {
        @include lib-vendor-prefix-display(flex);
        justify-content: center;

        .primary {
            @media screen and (max-width: $screen__m) {
                width: 100%;
            }

            .action {
                @extend .primary--full;
            }
        }
    }
}

.sales-guest-view {
    .page-main {
        max-width: 1440px;
        margin: auto;

        .order-actions-toolbar {
            padding-bottom: 20px;
            max-width: 100%;

            @media screen and (max-width: $screen__m) {
                padding-bottom: 0;
            }

            .actions {
                a {
                    background: $c-pale-grey;
                    border-radius: 4px;
                    width: 49%;
                    padding: 10px;
                    text-align: center;
                    display: inline-block;
                    vertical-align: center;
                    margin-right: 8px;
                    box-sizing: border-box;

                    @media screen and (max-width: $screen__m) {
                        width: 100%;

                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                    }

                    span {
                        @extend .button-as-link;
                        @extend .secondary--underline;

                        &:not(:first-child) {
                            margin-left: 20px;
                        }
                    }
                }
            }

            @media screen and (max-width: $screen__m) {
                max-width: 100%;
            }
        }
    }

    .column.main {
        display: flex;
        flex-direction: column;
    }

    .page-main .page-title-wrapper {
        text-align: center;
        width: 100%;

        @media screen and (max-width: $screen__m) {
            order: 2;
        }

        .page-title,
        .order-status {
            font-size: 26px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 1px;
            text-align: center;
            margin: 68px 0 0 0;
            display: inline-block;

            @media screen and (max-width: $screen__m) {
                margin: 0;
            }

            &:before {
                content: " - ";
            }
        }

        .page-title:before {
            display: none;
        }

        .order-date {
            margin-top: 9px;
            font-size: 16px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: center;
            color: $c-bluey-grey;
            padding-bottom: 20px;

            .label {
                font-weight: normal;
                font-size: 14px;
            }
        }
    }

    .page-title-wrapper {
        .page-title,
        .order-status {
            margin-bottom: 0;
        }
    }

    .order-links {
        display: none;
    }

    .table-order-items {
        > thead > tr > th {
            font-size: 16px;
            padding-bottom: 15px;
        }

        .items-qty {
            .item {
                list-style: none;
            }
        }
    }
    .block-order-details-view {
        margin-top: 56px;

        .block-title strong {
            font-weight: normal;
        }

        .block-content {
            display: block;

            > .box {
                width: 45%;
                display: inline-block;
                vertical-align: top;

                @media screen and (max-width: $screen__m) {
                    width: 100%;
                }

                &:nth-child(1),
                &:nth-child(2) {
                    margin-bottom: 35px;
                }
            }
        }
    }

    @media screen and (max-width: $screen__m) {

        .column.main {

            .block-order-details-view {
                margin-bottom: 40px;

                .block-content {
                    display: flex;
                    flex-direction: column;

                    .box {
                        margin-bottom: 30px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .actions-toolbar {
        .secondary {
            background: $c-pale-grey;
            border-radius: 4px;
            padding: 10px;
            width: 100%;
            text-align: center;
            display: inline-block;
            vertical-align: center;
            margin-right: 8px;
            box-sizing: border-box;

            .action.back {
                @extend .button-as-link;
                @extend .secondary--underline;
                width: auto;
            }
        }
    }



    .order-details-items {
        border-bottom: 0;

        @media screen and (max-width: $screen__m) {
            margin-top: 30px;
        }

        .order-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            border-bottom: solid 1px $c-pale-lilac;
            padding-bottom: 9px;
            margin-bottom: 70px;

            @media screen and (max-width: $screen__m) {
                margin-bottom: 6px;
            }

            strong {
                font-weight: normal;
                margin-top: 0;
            }
        }

        .order-items {
            .table > thead > tr > th,
            .table > thead > tr > td,
            .table > tbody > tr > th,
            .table > tbody > tr > td {
                border-bottom: solid 1px $c-pale-lilac
            }

            .table > thead > tr > th:not(.name),
            .table > tbody > tr > td:not(.name) {
                text-align: center;

                @media screen and (max-width: $screen__m) {
                    text-align: left;
                }
            }

            .table > thead > tr > th.subtotal,
            .table > tbody > tr > td.subtotal {
                text-align: right;

                @media screen and (max-width: $screen__m) {
                    text-align: left;
                }
            }
            .table > tfoot > tr {
                &:first-child {
                    td.amount {
                        padding: 20px 10px 8px 10px;
                    }
                }

                td.amount {
                    text-align: right;
                    padding: 8px 10px;
                }
            }

            .col {
                &.price,
                &.subtotal {
                    .price-excluding-tax .cart-price .price {
                        font-size: 14px;
                        font-weight: normal;
                    }
                }

                &.qty {
                    .title {
                        display: none;
                    }
                }
            }

            @media screen and (max-width: $screen__m) {
                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr td:before,
                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tbody > tr th:before {
                    content: attr(data-th);
                    font-size: 16px;
                }

                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tfoot > tr.totals.tax {
                    .mark {
                        width: 70%;
                    }
                }

                &.table-wrapper .table:not(.cart):not(.totals):not(.table-comparison) > tfoot > tr th {
                    text-align: right;
                }

                .product-item-name {
                    padding: 0;
                }

                .item-options,
                .item-options dd {
                    margin-bottom: 0;
                }

                .item-options dt {
                    font-size: 16px;
                }

                .item-options dd,
                .item-options dt {
                    display: inline-block;
                    font-weight: normal;
                }
            }
        }
    }

    .block-content {
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 540px) {
            flex-direction: column;
        }

        .box {
            width: 42%;

            @media screen and (max-width: $screen__m) {
                &:not(:last-child) {
                    margin-bottom: 27px;
                }
            }

            @media screen and (max-width: 540px) {
                width: 100%;
            }

            &-title {
                display: inline-block;
                margin-bottom: 12px;
                font-size: 16px;
                font-weight: 600;

            }

            &-content {
                font-size: 14px;

                p {
                    margin-bottom: 0;
                }
            }

            .action {
                @extend .button-as-link;
                @extend .secondary--underline;
                margin-top: 15px;

                &:before {
                    display: none;
                }
            }

            .action + .action {
                margin-left: 24px;
            }
        }
    }

    .block-order-details-view {
        margin-top: 56px;

        .block-title {
            padding-bottom: 8px;
            border-bottom: solid 1px $c-pale-lilac;
            width: 100%;
            text-align: left;

            font-size: 20px;
            font-weight: normal;
            letter-spacing: 0.5px;
            padding-right: 0;

            margin-bottom: 27px;
            display: inline-block;
        }
    }

    @media screen and (max-width: $screen__m) {

        .column.main {

            .block-order-details-view {
                margin-bottom: 40px;

                .block-content {
                    display: flex;
                    flex-direction: column;

                    .box {
                        margin-bottom: 30px;

                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
