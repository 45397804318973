.block-static-block {
    margin-top: 120px;

    @media screen and (max-width: $screen__m) {
        margin-top: 60px;
    }

    @media screen and (min-width: $screen__m) {
        margin-top: 60px;
        margin-left: -60px;
        margin-right: -60px;
    }
}
.cms-index-index {
    .block-static-block {
        @media screen and (max-width: $screen__m) {
            margin-top: 0;
            margin-left: -20px;
            margin-right: -20px;
        }

        @media screen and (min-width: $screen__m) {
            margin-top: 0;
            margin-left: -60px;
            margin-right: -60px;
        }

        + .block-static-block {
            @media screen and (max-width: $screen__m) {
                margin: 0;
            }

            @media screen and (min-width: $screen__m) {
                margin-top: 60px;
            }
        }
    }
}

// basic classes

.desktop-img {
    display: block;

    @media screen and (max-width: $screen__m) {
        display: none !important;
    }
}

.mobile-img {
    display: none;

    @media screen and (max-width: $screen__m) {
        display: block !important;
    }
}


.alternating-content {
    max-width: 995px;
    width: 100%;
    margin: 0 auto;
    @include lib-vendor-prefix-display(flex);
    justify-content: center;

    &:not(:last-child) {
        margin-bottom: 100px;
    }

    @media screen and (max-width: $screen__m) {
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }

    &.align-left {
        justify-content: flex-start;
    }

    &.align-right {
        justify-content: flex-end;
    }

    .content-block {
        @include lib-vendor-prefix-display(flex);
        flex-direction: row;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $screen__m) {
            flex-direction: column-reverse;
        }

        .inner-block {
            @media screen and (max-width: $screen__m) {
                width: 100%;
                text-align: center;
            }

            &:nth-child(2n) {
                text-align: right;

                @media screen and (max-width: $screen__m) {
                    width: 100%;
                    text-align: center;

                    img {
                        width: 100%;
                    }
                }
            }

            &.text-block {
                text-align: left;
                max-width: 435px;
                width: 100%;

                @media screen and (max-width: $screen__m) {
                    padding: 20px 0;
                    margin-top: 10px;
                    max-width: 100%;
                }

                .title,
                .description,
                .action {
                    margin-right: 100px;

                    @media screen and (max-width: $screen__m) {
                        margin-right: 0;
                    }
                }

                .title {
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 33px;
                    letter-spacing: 0.12em;
                    text-transform: uppercase;
                    color: $c-black;
                    margin-bottom: 25px;

                    @media screen and (max-width: $screen__m) {
                        margin-top: 20px;
                    }
                }

                .description {
                    font-size: 14px;
                    line-height: 22px;
                    color: $c-black;
                    margin-bottom: 30px;
                }
            }
        }

        &.img-right {
            flex-direction: row-reverse;

            @media screen and (max-width: $screen__m) {
                flex-direction: column-reverse;
            }

            .inner-block {
                &:nth-child(2n) {
                    text-align: left;
                }

                &.text-block {
                    .title,
                    .description,
                    .action {
                        margin-right: 0;
                        margin-left: 100px;

                        @media screen and (max-width: $screen__m) {
                            margin-left: 0;
                        }
                    }

                    @media screen and (max-width: $screen__m) {
                        margin: 20px 0;
                    }
                }
            }
        }
    }
}


.double-banner,
.single-banner {
    max-width: 1440px;
    margin: 0 auto;
    @include lib-vendor-prefix-display(flex);
    flex-direction: row;

    @media screen and (max-width: $screen__m) {
        flex-direction: column;
    }

    .inner-block {
        position: relative;

        img {
            display: inline-block;
            vertical-align: top;

            @media screen and (max-width: $screen__m) {
                width: 100%;
            }
        }

        .text-block {
            @include lib-vendor-prefix-display(flex);
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;

            .title {
                font-weight: 600;
                font-size: 24px;
                line-height: 33px;
                letter-spacing: 0.12em;
                text-transform: uppercase;
                color: $c-black;
                margin-bottom: 0;
                text-align: center;

                @media screen and (max-width: $screen__m) {
                    padding: 0 20px;
                    font-weight: normal;
                }
            }

            .primary--full {
                margin-top: 25px;
            }
        }
    }
}

.single-banner {
    .inner-block .text-block {
        .primary--full {
            margin-top: 35px;
        }
    }
}


.company-values {
    max-width: 1440px;
    margin: 0 auto;
    @include lib-vendor-prefix-display(flex);
    justify-content: center;
    align-items: center;
    background: $c-red01;
    padding: 73px 0;

    @media screen and (max-width: $screen__m) {
        flex-direction: column;
        flex-wrap: wrap;
        padding: 100px 0;
    }

    .inner-block {
        width: 100%;
        max-width: 230px;
        color: $c-white;
        margin: 0 5.21%;
        text-align: center;

        @media screen and (max-width: $screen__m) {
            &:not(:first-child) {
                margin-top: 70px;
            }
        }

        .icon {
            font-size: 104px;
            display: contents;
        }

        .text-block {
            p {
                color: $c-white;
                margin-bottom: 0;
            }

            .title {
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                margin-top: 29px;
            }

            .description {
                font-size: 14px;
                line-height: 22px;
                margin-top: 13px;
            }
        }
    }
}

.ambrand-index-index, {

    .single-banner {

        @media screen and (min-width: 769px) {
            margin: 0 -102px;
        }
    }
}

.widget-accordion {
    .title-accordion {
        margin-top: 5px;
        background-color: $c-white;
        color: #020B41;
        cursor: pointer;
        min-height: 50px;
        border: none;
        text-align: left;
        outline: none;
        font-size: 15px;
        display: flex;
        padding: 0 20px;
        border: 1px solid #020B41;

        &:hover, &.allow.active {
            background-color: #020B41;
            color: $c-white;
        }

        > div {
            height: 100%;
            width: 100%;
            background-image: url('../images/icons/arrow-down.svg');
            background-repeat: no-repeat;
            background-position-x: right;
            background-position-y: center;
            background-size: 25px;

            h4 {
                max-width: 90%;
            }
        }
    }
}