.store-listing {
    &.cms-page-wrapper-width {
        max-width: 1130px;
        margin: auto;
    }

    &-top-banner {
        margin-bottom: 58px;

        @media screen and (min-width: $screen__m) and (max-width: $screen__l) {
            margin-bottom: 30px;
            margin-left: -40px;
            margin-right: -40px;
        }

        @media screen and (max-width: $screen__m) {
            margin-bottom: 30px;
            margin-left: -20px;
            margin-right: -20px;
        }
    }

    .margin-top-30 {
        margin-top: 30px;
        display: block;
    }

    .margin-top-15 {
        margin-top: 15px;
        display: block;
    }

    &-store {
        background: $c-white;
        box-shadow: 1px 4px 17px rgba(0, 0, 0, 0.08);
        border-radius: 3px;
        display: flex;
        align-items: center;
        padding: 30px 18px;
        gap: 15px;

        @media screen and (max-width: $screen__l) {
            flex-direction: column-reverse;
        }

        &:not(:last-child) {
            margin-bottom: 20px;
        }

        &.flex-reverse {
            flex-direction: row-reverse;

            @media screen and (max-width: $screen__l) {
                flex-direction: column-reverse;
            }
        }
    }

    &-image-wrapper {
        width: 50%;

        @media screen and (max-width: $screen__l) {
            width: 100%;
            text-align: center;
        }

        img {
            border-radius: 3px;
        }
    }

    &-content {
        width: 50%;
        text-align: center;
        padding: 0 10px;

        @media screen and (max-width: $screen__l) {
            margin-top: 15px;
            width: 100%;
        }

        p {
            margin-bottom: 8px;
            letter-spacing: 0.005em;
            font-size: 16px;
            line-height: 24px;

            @media screen and (max-width: $screen__l) {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    &-action-primary {
        display: block;
        @extend .primary--full;
        width: max-content;
        min-width: 185px;
        height: 50px;
        line-height: 50px;
        margin: auto;
        padding: 0;
    }

    .black-link-icon {
        @include black-link($icon: true, $color: $c-dark);
    }
}
