$product-page__max-width: 1440px !default;

.breadcrumbs {
    margin: 16px 0;
    max-width: $product-page__max-width;

    @media screen and (min-width: 1024px) {
        padding: 0 102px !important;
    }

    .items {
        display: flex;

        @media screen and (max-width: $screen__m) {
            display: block;
            margin-bottom: 10px;
        }
    }

    .item {
        font-size: 12px;
        text-align: center;
        color: $c-dark;

        @media screen and (max-width: $screen__m) {
            display: inline;
        }

        &:after {
            content: "/";
            padding: 0 10px;
            white-space: pre;
        }

        &:last-of-type {

            &:after {
                content: none;
            }
        }
    }
}
