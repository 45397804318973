.pages {
    @include lib-pager();
    margin-top: 126px;

    @media screen and (max-width: $screen__m) {
        margin-top: 80px;
    }

    .item {
        &.current {
            strong.page,
            a.page {
                background-color: $c_red01;
                color: $c-white;
            }
        }
    }

    strong.page,
    a.page {
        width: 50px;
        height: 50px;
        line-height: 50px;
        padding: 0;
        font-weight: normal;
        font-size: 1.4rem;
        color: $c-dark;

        @media screen and (max-width: $screen__m) {
            width: 40px;
            height: 40px;
            line-height: 40px;
        }
    }

    a.page:hover {
        background-color: $c_red01;
        color: $c-white;
    }

    .pages-item-previous,
    .pages-item-next {
        font-weight: normal;
        background-color: $c_red01;

        a {
            width: 100px;
            line-height: 50px;
            color: $c-white;

            @media screen and (max-width: $screen__m) {
                line-height: 40px;
            }

            &:hover {
                color: $c-white;
                background-color: $c-dark;
            }
        }
    }

    .pages-item-previous {
        margin-right: 30px;

        @media screen and (max-width: $screen__m) {
            margin-right: 0;

            a.action.previous {
                width: 40px;
                height: 40px;
                display: block;
                position: relative;

                span {
                    display: none;
                }

                &:after {
                    content: '';
                    background: url('../images/icons/prev.svg') no-repeat;
                    display: inline-block;
                    background-position: center;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .pages-item-next {
        margin-left: 30px;

        @media screen and (max-width: $screen__m) {
            margin-left: 0;

            a.action.next {
                width: 40px;
                height: 40px;
                display: block;
                position: relative;

                span {
                    display: none;
                }

                &:after {
                    content: '';
                    background: url('../images/icons/next.svg') no-repeat;
                    display: inline-block;
                    background-position: center;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    @media screen and (max-width: $screen__m) {
        .items {
            width: 100%;
            display: flex;
            flex-wrap: nowrap !important;
            justify-content: center !important;
            align-items: center;

            .item {
                border: none !important;
            }

            .pages-item-next {
                margin-top: 0 !important;

            }
        }
    }
}
