.page-main {
    .brand-listing-page {
        max-width: 1070px;
        width: 100%;
        margin: auto;
    }

    .brands-listing {
        &.page-title-wrapper {
            text-align: center;

            .page-title {
                margin: 68px 0 62px 0;
                span {
                    font-weight: bold;
                }
            }
        }
    }

    .brands-filters,
    .brand-content,
    .brands-letters-list {
        li {
            list-style: none;
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    }

    .brands-filters {
        display: none;
    }

    .brands-letters-list {
        display: flex;
        padding-left: 0;

        > li {
            flex-basis: auto;
            text-align: center;
            text-transform: uppercase;
            width: 100%;
            max-width: 38px;
            font-size: 16px;

            &.selected {
                a {
                    font-weight: bold;
                }
            }

            a {
                color: $c_black;
                font-weight: 300;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .brands-content {

        ul li {
            list-style: none;
            display: block;
            width: 100%;
            margin: 0;
            padding: 0;
        }

        .logos {
            .block-title {
                display: none;
            }

            .footer-logo-list ul li {
                width: calc(20% - 30px);
            }

            @media screen and (max-width: 480px) {
                .footer-logo-list {
                    padding: 0;
                    margin-top: -60px;

                    ul {
                        display: flex;
                        flex-wrap: wrap;

                        li {
                            width: 50%;
                        }
                    }
                }
            }
        }

        .brand-item {
            &.hidden {
                display: none !important;
            }

            .brand-item-title {
                border-bottom: 1px solid $c_black;

                position: relative;

                @include icomoon("arrow-down", "after", true, true) {
                    font-size: 10px;
                    color: $c-dark;
                }

                &:after {
                    display: none;

                    @media screen and (max-width: 960px) {
                        display: block;
                        position: absolute;
                        top: calc(50% - 6px);
                        right: 0;
                        transform: translateY(-50%);
                    }
                }

                h3 {
                    font-weight: 300;
                    line-height: 1.1;
                    font-size: 32px;
                    margin-top: 27px;
                    margin-bottom: 18px;

                    @media screen and (max-width: 960px) {
                        font-size: 25px;
                    }
                }
            }

            .brand-item-list {
                column-count: 4;
                column-gap: 20px;
                padding: 20px 0;
            }

            .brand-item-content {
                margin-bottom: 20px;
            }

            .item {
                display: flex;
                justify-content: flex-start;

                &:hover {
                    text-decoration: underline;

                    .count {
                        text-decoration: none;
                    }
                }

                .count {
                    color: $c_black;
                    font-weight: 300;
                    font-size: 16px;
                }

                .label {
                    color: $c_black;
                    font-weight: normal;
                    text-transform: uppercase;
                    font-size: 16px;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    @media screen and (max-width: 960px) {
        .brands-letters {
            display: none;
        }

        .brand-item {
            margin-bottom: 20px;

            &.shown {
                .brand-item-title {

                    &:after {
                        transform: translateY(-50%) rotate(180deg);
                    }
                }

                .brand-item-list {
                    display: flex;
                    flex-direction: column;

                    .brand-item-content {
                        &:last-child {
                            margin-bottom: 0;
                        }

                        .label,
                        .count {
                            font-size: 14px;
                        }
                    }
                }
            }

            .brand-item-list {
                display: none;
            }
        }
    }

    @media screen and (max-width: 680px) {
        .brand-item {
            .brand-item-list {
                column-count: 3;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .brand-item {
            .brand-item-list {
                column-count: 2;
            }
        }
    }

    @media screen and (max-width: 480px) {
        .brand-item {
            .brand-item-list {
                column-count: 1;
            }
        }
    }

}

.ambrand-index-index {

    .sidebar, #amasty-shopby-product-list, .toolbar {
        display: none;
    }

    .column.main {
        min-height: initial;
    }

    .category-view {
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        
        .category-image {
            @media screen and (max-width: $screen__m) {
                margin-bottom: 0;
            }
        }
        
        .category-description-wrapper {
            display: none;
        }
    }
}



.amshopby-filters-bottom-cms {
    margin-top: 0;
    width: 100%;

    .alternating-content:first-child {
        .content-block {
            flex-direction: column;

            .inner-block:not(.text-block) {
                margin-left: -20px;
                margin-right: -20px;
                width: calc(100% + 40px);
            }

            .inner-block {
                margin-top: 0;
            }
        }
    }

    .align-left {
        max-width: 1236px;
        justify-content: flex-start;
        margin-bottom: 0;
    }

    .company-values {
        max-width: 996px;
        background-color: #fff;
        padding: 20px 0 70px;
        flex-direction: row;

        .inner-block {
            max-width: 230px;
            width: calc(50% - 40px);
            margin: 10px;
            margin-bottom: 20px;

            .text-block .title {
                color: #253B80;
                font-size: 12px;
                font-weight: 400;
                margin-top: 15px;
            }
        }
    }

    .after-single-banner {
        background-color: #81252A;
        max-width: 1440px;
        padding: 40px 20px;
        margin: 0 -20px;
        margin-bottom: 80px;

        .content-block .inner-block.text-block  {
            .title {
                color: #fff;
                text-align: center;
                font-size: 20px;
                line-height: 27px;
            }
            .description {
                color: #fff;
                font-size: 16px;
                text-align: center;
                line-height: 29px;
            }
        }
    }

    .product-button-link {
        display: flex;
        flex-direction: column;

        .primary--border {
            font-size: 18px;
            width: 100%;
            margin-bottom: 20px;
            box-sizing: border-box;
            padding-left: 0;
            padding-right: 0;
            text-transform: none;
        }
    }

    .product-description {
        margin: 60px auto 10px;
    }
}

@media screen and (min-width: 768px) {
    .amshopby-filters-bottom-cms {
        margin-top: 45px;

        .alternating-content:first-child {
            .content-block {
                flex-direction: row;

                .inner-block:not(.text-block) {
                    margin-left: unset;
                    margin-right: unset;
                    width: 100%;
                }
            }
        }

        .inner-block.text-block {
            margin-left: 74px;

            .title, .description {
                margin-right: 0 !important;
            }
        }

        .company-values {
            padding: 100px 0 80px;

            .inner-block {
                width: calc(100% - 80px);
            }
        }

        .after-single-banner {
            padding: 100px 0;
            margin: 0 -102px;
            margin-bottom: 100px;
            width: auto;

            .content-block .inner-block.text-block  {
                margin: 0 auto;
                max-width: 900px;

                .title {
                    font-size: 30px;
                    line-height: 40px;
                }
                .description {
                    font-size: 18px;
                    line-height: 24px;
                }
            }
        }

        .product-description {
            max-width: 1006px;
            margin: 60px auto;
        }
        .product-button-link {
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            .primary--border {
                width: max-content;
                margin: 26px;
                margin-bottom: 25px;
                padding: 16px 25px;
            }
        }
    }
}

.amslider {

    .owl-stage {
        display: flex;
        justify-content: center;
        align-items: center;

        .owl-item {
            .swiper-slide {
                .swiper-brand {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
