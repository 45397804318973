
//
//  Checkout Progress Bar
//  ---------------------------------------------

ul.opc-progress-bar {
    @extend .abs-reset-list;
    counter-reset: i;
    max-width: 1100px;
    margin: 50px auto 0;
    padding: 0 0 20px;
    border-bottom: 2px solid #142688;

    @media screen and (max-width: $screen__m) {
        margin: 20px auto 0;
        padding-left: 0;
    }
}

.opc-progress-bar-item {
    display: inline-block;
    position: relative;
    letter-spacing: 1px;
    color: $c-bluey-grey;

    a {
        color: $c-bluey-grey;

        &:hover {
            color: $c-bluey-grey;
            text-decoration: none;
        }
    }

    &._complete {
        cursor: pointer;
    }

    > span {
        display: inline-block;
        width: 100%;
        word-wrap: break-word;

        &:before {
            content: "0" counter(i) ". ";
            counter-increment: i;
            white-space: pre;
        }

        &:after {
            content: " / ";
            white-space: pre;
            color: $c-bluey-grey;
        }

        @media screen and (max-width: $screen__m) {
            &:before {
                display: none;
            }

            &:after {
                content: " / ";
                white-space: pre;
                padding: 0 12px;
            }
        }
    }

    &:last-of-type > span {

        &:after {
            content: none;
        }
    }

    &._active {
        color: $c-dark;
        font-weight: 600;
    }
}

@include max-screen($screen__m) {

    .opc-progress-bar {
        text-align: left;
    }
}
