//
//  Variables
//  _____________________________________________

$form-field__vertical-indent__desktop: 29px !default;
$form-calendar-icon__color: $primary__color !default;

.fieldset {
    @include lib-form-fieldset();

    &:last-child {
        margin-bottom: 0;
    }

    > .field,
    > .fields > .field {
        @include lib-form-field();

        &.no-label {

            > .label {
                @extend .abs-visually-hidden;
            }
        }

        &.choice {

            .label {
                display: inline;
                font-weight: normal;
            }
        }

        .label {
            .column:not(.main) & {
                font-weight: normal;
            }
        }

        .field.choice {
            margin-bottom: $indent__s;

            &:last-child {
                margin-bottom: 0;
            }
        }

        input[type=file] {
            margin: $indent__xs 0;
        }

        .field-tooltip {
            .action-help {
                @include icomoon("question-mark", "before", true, true) {
                    color: $c_grey;
                    font-size: 20px;
                }
            }
        }
    }
}

.legend + .fieldset,
.legend + div {
    clear: both;
}

.legend {

    strong {
        margin-left: $indent__xs;
    }
}

fieldset.field {
    border: 0;
    padding: 0;
}

.field {

    &.date {
        @extend .abs-field-date;

        .time-picker {
            display: inline-block;
            margin-top: $indent__s;
            white-space: nowrap;
        }
    }

    .message {

        &.warning {
            margin-top: $indent__s;
        }
    }
}

.field-error,
div.mage-error[generated] {
    margin-top: 7px;
}

.field-error {
    @include lib-form-validation-note();
}

.field .tooltip {
    @include lib-tooltip(right);

    .tooltip-content {
        min-width: 200px;
        white-space: normal;
    }
}

input,
select {

    &:focus ~ .tooltip .tooltip-content {
        display: block;
    }
}

._has-datepicker {

    ~ .ui-datepicker-trigger {
        @include lib-button-reset();
        @include lib-icon-font(
            $_icon-font-content: $icon-calendar,
            $_icon-font-color: $primary__color__lighter,
            $_icon-font-size: $icon-calendar__font-size,
            $_icon-font-line-height: $icon-calendar__font-size,
            $_icon-font-display: block,
            $_icon-font-text-hide: true
        );
        display: inline-block;
        vertical-align: middle;

        &:focus {
            box-shadow: none;
            outline: 0;
        }
    }
}

//
//  Sidebar forms
//  -----------------------------------------

.sidebar {

    .fieldset {
        margin: 0;

        & > .field:not(.choice),
        .fields > .field {

            &:not(:last-child) {
                margin: 0 0 $form-field__vertical-indent;
            }

            .label {
                margin: 0 0 4px;
                padding: 0 0 $indent__xs;
                text-align: left;
                width: 100%;
            }

            .control {
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    .fieldset {

        .legend {
            @extend .abs-margin-for-forms-desktop;
        }

        > .field {
            @include lib-form-field-type-revert();
            margin: 0 0 $form-field__vertical-indent__desktop;
        }
    }
}


// Alternative Theme specific styling.
// ---------------------------------------------------------------------------
.label,
.fieldset > .field > .label,
.fieldset > .fields > .field > .label {
    font-weight: 600;
    font-size: 1.3rem;

    .opc-wrapper & {
        font-weight: 600;
    }
}

select {
    appearance: none;
    height: auto;
    padding: 14px 30px 14px 15px;
    box-shadow: 0 0 0 1px $c-pale-lilac inset;
    border: none;
    border-radius: 4px;
    color: $c-dark;
    background-image: url('../images/icons/select-arrow-down.svg');
    background-repeat: no-repeat;
    background-position: 96% 16px;

    &:not([disabled]):focus {
        outline: none;
        box-shadow: 0 0 0 1px $c-dark inset;
    }

    .field._error &,
    &.mage-error {
        outline: none;
        box-shadow: 0 0 0 2px $c-strawberry inset;
    }
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"],
input[type="tel"] {
    border: 1px solid $c-pale-lilac;
    box-shadow: 0 0 0 1px $c-pale-lilac inset;
    background: $c-white;
    border-radius: 4px;
    color: $c-dark;
    padding: 15px;
    height: auto;

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px #142688 inset;
    }

    .field._error &,
    &.mage-error {
        outline: none;
        box-shadow: 0 0 0 2px $c-strawberry inset;
    }
}

input[type="radio"],
input[type="checkbox"] {
    position: relative;
    margin-right: 10px;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background-size: 18px 18px;
        background-repeat: no-repeat;
    }

    &:focus {
        outline: none;
        box-shadow: none;
    }
}

input[type="radio"] {

    &:before {
        background-image: url('../images/forms/radio.svg');
    }

    &:checked:before {
        background-image: url('../images/forms/radio-selected.svg');
    }
}

input[type="checkbox"] {

    &:before {
        background-image: url('../images/forms/checkbox.svg');
        background-color: $c-white;
    }

    &:checked:before {
        background-image: url('../images/forms/checkbox-selected.svg');
    }
}
