.inventory-available-modal {
    pointer-events: all;
    
    .modal-inner-wrap {
        top: 0;
        transform: unset;
    }
    
    .modal-header {
        background: $c_main_blue;

        .modal-title {
            color: $c-white;
            padding: 40px 0 17px 0;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 30px;
            line-height: 41px;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            @media screen and (max-width: $screen__m) {
                font-size: 20px;
                line-height: 27px;
                padding: 40px 0 24px 0;
            }
        }

        &-description {
            color: $c-white;
            max-width: 440px;
            margin: auto;
            padding: 0 20px 41px 20px;
            text-align: center;
            font-size: 14px;
            line-height: 22px;

            @media screen and (max-width: $screen__m) {
                padding: 0 0 11px 0;
            }
        }

        .action-close {
            right: 30px;

            &:before {
                color: $c-white;
            }
        }
    }

    .inventory-store-list {
        padding: 10px 80px;
        display: flex;
        flex-direction: column-reverse;

        @media screen and (max-width: $screen__m) {
            padding: 10px 20px;
            max-height: 100%;
            overflow-y: unset;
        }

        .inventory-item {
            display: flex;
            justify-content: space-between;
            padding-bottom: 40px;
            
            &.warehouse {
                display: none;
            }
            
            &:not(:first-child) {
                border-bottom: 1px solid #D8D8D8;
            }

            &:not(:last-child) {
                margin-top: 40px;
            }

            &:first-child {
                padding-bottom: 0;

                .inventory-info  {
                    &:after {
                        height: calc(100% + 40px);
                    }
                }
            }

            &:last-child {
                .inventory-info  {
                    &:after {
                        height: calc(100% + 40px);
                        top: 0;
                    }
                }
            }

            .inventory-img {
                width: 180px;
                height: 130px;
                margin-right: 20px;

                @media screen and (max-width: $screen__m) {
                    display: none;
                }

                &.cambridge {
                    background: url('../images/sourceImg/cambridge.jpg') no-repeat;
                }

                &.laval {
                    background: url('../images/sourceImg/laval.jpg') no-repeat;

                }

                &.levis {
                    background: url('../images/sourceImg/levis.jpg') no-repeat;
                }
            }


        }

        .inventory-info {
            flex: 1;
            margin-right: 37px;
            position: relative;

            img {
                @media screen and (max-width: $screen__m) {
                    display: none;
                }
            }
            
            &:after {
                content: '';
                width: 1px;
                background: #D8D8D8;
                height: calc(100% + 80px);
                display: block;
                position: absolute;
                top: -40px;
                right: 0;

                @media screen and (max-width: $screen__m) {
                    display: none;
                }
            }

            .inventory-title {
                font-weight: 600;
                font-size: 14px;
                line-height: 19px;
                letter-spacing: 0.08em;
                padding-bottom: 14px;
                text-transform: uppercase;
            }

            .inventory-address,
            .inventory-phone {
                font-size: 14px;
                line-height: 19px;
            }

            .inventory-phone {

            }
        }


        .inventory-availability {
            background: #F5F5F5;
            width: 100px;
            height: 130px;
            border-radius: 4px;

            .available,
            .low,
            .out-of-stock {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                margin: 42px auto 15px;
                display: block;
            }

            .available {
                background: #34C402;
            }

            .availability-text {
                text-align: center;
                display: block;
            }

            .low {
                background: #F3A100;
            }

            .out-of-stock {
                background: #F42837;
            }
        }
    }

    .modal-footer {
        padding-top: 0;
        padding-bottom: 48px;
        text-align: center;

        .action-secondary {
            @extend .button-as-link;
            @extend .secondary--underline;
            font-size: 14px;
            line-height: 19px;
            margin: auto;
            letter-spacing: normal;
            text-transform: capitalize;
            display: inline-block;
        }
    }

    .modal-header,
    .modal-content,
    .modal-footer {
        padding: 25px 20px 30px 20px;
    }
}
