//
//  Variables
//  _____________________________________________

$autocomplete__background-color  : $c_grey03 !default;
$autocomplete__border            : 1px solid $form-element-input__border-color !default;
$autocomplete-item__border       : 1px solid $color-gray90 !default;
$autocomplete-item__hover__color : $color-gray91 !default;
$autocomplete-item-amount__color : $color-gray60 !default;

.block-search {
    margin-bottom: 0;
    order: 2;

    .block {
        &-title {
            display: none;
        }
    }

    .block-content {
        margin-bottom: 0;
    }

    .label,
    .label:before {
        display: none;
    }

    .action.search {
        display: block;

        @include icomoon("search", "before", true, true) {
            font-size: 20px;
            color: $color-black;
        }

        @media screen and (max-width: $screen__l) {
            &:before {
                color: $c-white;
            }
        }
    }

    .control {
        clear: both;
        margin: 0 (-$layout__width-xs-indent) -1px;
        padding: 0 $layout__width-xs-indent;
    }

    input {
        left: -300%;
        margin: 15px 0;
        position: absolute;
    }

    .nested {
        display: none;
    }
}

.search-autocomplete {
    @extend .abs-add-box-sizing;
    display: none;
    margin-top: -15px;
    overflow: hidden;
    position: absolute;
    z-index: 3;

    ul {
        @include lib-list-reset-styles();

        li {
            @include lib-css(border-top, $autocomplete-item__border);
            cursor: pointer;
            margin: 0;
            padding: $indent__xs $indent__xl $indent__xs $indent__s;
            position: relative;
            text-align: left;
            white-space: normal;

            &:not(:empty) {
                border-top: 0;
                @include lib-css(border, $autocomplete__border);
                @include lib-css(background, $autocomplete__background-color);
            }

            &:first-child {
                border-top: none;
            }

            &:hover,
            &.selected {
                @include lib-css(background, $autocomplete-item__hover__color);
            }

            .amount {
                @include lib-css(color, $autocomplete-item-amount__color);
                position: absolute;
                right: 7px;
                top: $indent__xs;
            }
        }
    }
}

.form.search.advanced {
    .fields.range {
        .field {
            &:first-child {
                position: relative;

                .control {
                    padding-right: 25px;

                    &:after {
                        content: ' \2013 ';
                        display: inline-block;
                        position: absolute;
                        right: 0;
                        text-align: center;
                        top: 6px;
                        width: 25px;
                    }
                }
            }

            &:last-child {
                position: relative;

                div.mage-error[generated] {
                    left: 0;
                    position: absolute;
                    top: 32px;
                }
            }

            &.with-addon {
                .control {
                    padding-right: 45px;
                }
            }
        }
    }

    .group.price {
        .addon {
            .addafter {
                background: none;
                border: 0;
                padding-top: 6px;
                position: absolute;
                right: 0;
                top: 0;
            }
        }
    }
}

.search.summary {
    margin-bottom: $indent__s;
}

.block-search {
    position: relative;
    max-width: 700px;
    width: 100%;
    z-index: 4;
    margin: 0 4%;

    @media screen and (max-width: $screen--l) {
        max-width: 100%;
        margin: 0;

        .top-container & {
            padding: 0 20px;
            box-sizing: border-box;
        }
    }

    .label {
        @extend .abs-visually-hidden-desktop;
    }

    .control {
        border-top: 0;
        margin: 0;
        padding: 0;
    }

    .nested {
        display: block;
        padding-top: 5px;
        position: absolute;
    }

    input {
        @include lib-input-placeholder($form-element-input-placeholder__color);
        margin: 0;
        padding: 0 35px 0 15px;
        position: static;
        background: $c-grey03;
        border-radius: 40px;
        line-height: 40px;
        box-shadow: none;
    }

    .action.search {
        display: inline-block;
        @include lib-button-reset();
        padding: 0;
        position: absolute;
        right: 15px;
        top: 9px;
        z-index: 1;

        @media screen and (max-width: $screen--l) {
            right: 35px;
        }

        &:focus {
            &:before {
                @include lib-css(color, $color-gray20);
            }
        }
    }
}

.search-autocomplete {
    margin-top: 0;
}
