//
//  Product Lists
//  _____________________________________________
.catalog-category-view {
    .breadcrumbs {
        @media screen and (max-width: $screen__m) {
            display: none;
        }
    }

    .page-main > .page-title-wrapper {
        text-align: center;
        padding: 30px;
        display: none;

        @media screen and (max-width: $screen__m) {
            display: block;

            .page-title {
                padding: 0;
                margin: 0;
            }
        }
    }

    .sidebar-main {
        .page-title {
            font-weight: normal;
            font-size: 24px;
            line-height: 33px;
            color: $c-dark;
            margin-bottom: 21px;

            @media screen and (max-width: $screen__m) {
                margin: 0;
                padding: 17px 40px 0 40px;
                background: $c-white;
                max-width: 235px;
            }
        }
    }
}
.category-view {
    @include lib-vendor-prefix-display(flex);
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 45px;

    @media screen and (max-width: $screen__m) {
        margin-bottom: unset;
        margin-top: 20px;
    }

    .category-description-image-wrapper {
        @include lib-vendor-prefix-display(flex);
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;

        @media screen and (max-width: $screen__m) {
            margin: 0 -20px 14px -20px;
            flex-direction: column;

            p {
                margin-bottom: 0;
            }
        }

        .category-description-wrapper {
            max-width: 432px;
            margin-left: 74px;

            @media screen and (max-width: $screen__m) {
                margin: 0 1.6rem;
            }

            .page-title-wrapper{
                .page-title{
                    font-size: 30px;
                    line-height: 41px;
                    letter-spacing: 0.1em;
                    color: $c-dark;
                    font-weight: 600;

                    .parent {
                        color: $color-black;
                        font-weight: 300;
                        font-size: 12px;
                        line-height: 16px;
                        letter-spacing: 3px;
                        text-transform: uppercase;

                        &:before {
                            content: '/';
                            padding: 0 8px 0 3px;
                        }
                    }
                }

                @media screen and (max-width: $screen__m) {

                }
            }

            .category-description {
                margin-top: 27px;
                font-size: 14px;
                line-height: 22px;
                color: $c-dark;

                @media screen and (max-width: $screen__m) {
                    text-align: left;
                }
            }
        }

        .category-image {
            max-width: 730px;
            margin-bottom: 12px;

            @media screen and (max-width: $screen__m) {
                margin-bottom: 44px;
            }

        }
    }

    .category-cms {
        width: 100%;

        @media screen and (max-width: $screen__m) {
            display: none;
        }

        ol {
            text-align: center;
            margin: 22px 0;

            li {
                display: inline-block;
                font-size: 14px;
                letter-spacing: 0.5px;
                text-align: center;
                text-transform: uppercase;
                color: $c-white;
                margin-bottom: 0;
                padding: 0;

                &:not(:last-child) {
                    margin-right: 40px;
                }
            }
        }
    }

}

.products {
    margin: $indent__l 0;

    ol.products.list.items.product-items:not(.owl-carousel) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;

        @media screen and (max-width: $screen__l) {
            grid-template-columns: 1fr 1fr;
        }
    }
}

.product {

    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        &-row {
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            order: 1;
        }

        &-name {
            @extend .abs-product-link;
            display: inline-block;
            word-wrap: break-word;
            hyphens: auto;
            font-size: 14px;
            line-height: 20px;
            color: $c-dark;
            padding: 7px 0 0 0;
            width: 80%;

            @media screen and (max-width: $screen__m) {
                padding: 25px 0 0 0;
                line-height: 20px;
            }
        }

        &-inner {
            display: none;
            width: 15%;
            vertical-align: top;
            text-align: right;
            padding-top: 7px;

            @media screen and (max-width: $screen__m) {
                padding-top: 25px;
            }
        }

        &-info {
            max-width: 100%;

            .product-image-container {
                width: 100% !important;
            }
        }

        &-details {
            margin-bottom: 30px;
            display: flex;
            flex-direction: column;

            .amshopby-option-link {
                order: 2;
                text-transform: capitalize;
                font-size: 14px;
                line-height: 20px;
                font-weight: 600;

                @media screen and (max-width: $screen__m) {
                    padding-bottom: 0;
                }
            }
        }

        &-actions {

            .actions-secondary {

                & > .action {
                    @extend .abs-actions-addto-gridlist;

                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .price-box {
            padding-bottom: 27px;
            @include lib-vendor-prefix-display();
            flex-direction: row-reverse;
            justify-content: flex-end;
            order: 3;
            margin-top: 11px;
            gap: 4px;

            @media screen and (max-width: $screen__m) {
                padding-bottom: 0;
            }

            .price {
                font-size: 14px;
                white-space: nowrap;
                color: $c-dark;
            }
        }

        .special-price,
        .minimal-price {
            .price {
                font-size: 14px;
            }

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: inline-block;

            .price {
                font-weight: normal;
                color: $c-strawberry;
                padding-left: 10px;
            }
        }

        .old-price {
            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link {
            margin-top: 5px;

            .price-label {
                color: $link__color;
                font-size: 14px;
            }

            .price {
                font-weight: $font-weight__regular;
            }
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .action.tocompare {
            @include lib-icon-font-symbol($icon-compare-empty);
        }

        .tocart {
            white-space: nowrap;
        }

        .product-brand-name {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-transform: uppercase;
            padding: 0;
        }
    }
}

.price-container {

    .price {
        font-size: 14px;
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        font-size: 11px;
    }

    .weee {

        &:before {
            content: '('attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {

    .products-list .product {

        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    body[class*="categorypath-"],
    .catalog-category-view,
    .category-default-category {
        .products-grid .items:not(.owl-carousel) {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
        }
    }
}

@include min-screen($screen__s) {

    .product {

        &-item {

            &-actions {
                display: block;

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: table-cell;
                }
            }
        }
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {

        .products-grid {

            .product-item {
                display: inline-block;

                &:nth-child(3n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}

.page-product-material-list {
    .toolbar-products {
        .toolbar__center {
            margin: 0 auto;
        }

        .toolbar__right {
            display: none;
        }
    }

    .products-grid .product-item {
        width: 22.5%;
        max-width: 245px;

        @media screen and (max-width: $screen__m) {
            width: 48%;
        }
    }
}

.catalog-product-view {
    &.page-product-grouped {
        .product-info-main {
            .custom-range-price {
                display: none;
            }
        }
    }
}
